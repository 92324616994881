import { useAuth } from "../../AuthProvider";
import { BottomNavHeightMobile } from "../../constants";
import Referral from "../Referral/Referral";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import PersonAddAltOutlinedIcon from "@mui/icons-material/PersonAddAltOutlined";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import {
  BottomNavigation,
  BottomNavigationAction,
  Divider,
  Drawer,
  DrawerProps,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Popover,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

type NavigationItemKey =
  | "chat"
  | "document"
  | "ppt"
  | "topup"
  | "referral"
  | "logout"
  | "profile";

interface NavigationItem {
  key: NavigationItemKey;
  icon: React.ReactElement;
  text: string;
  href?: string;
  onClick?: (
    event: React.MouseEvent<HTMLElement>,
    item: NavigationItem
  ) => void;
}

interface ProfileMenuProps {
  onReferralButtonClick: () => void;
}

function ProfileMenu({ onReferralButtonClick }: ProfileMenuProps) {
  const theme = useTheme();
  const styles = {
    items: {
      display: "flex",
      flexDirection: "column",
      background: theme.palette.grey[700],
      width: "88px",
      borderRadius: "8px",
    },
    mobileItemButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    mobileItemButtonColor: {
      color: "#f5f5f5",
    },
    mobileItemButtonSelectedColor: {
      color: theme.palette.primary.main,
    },
    itemIcon: {
      minWidth: "auto",
      color: "inherit",
    },
    mobileItemText: {
      fontSize: "0.875rem",
    },
    divider: {
      width: "100%",
      border: "1px solid #4d4d4d",
    },
  };

  const [selectedItemKey, setSelectedItemKey] =
    useState<NavigationItemKey | null>(null);
  const auth = useAuth();
  const navigate = useNavigate();

  const handleItemClick = (
    event: React.MouseEvent<HTMLElement>,
    item: NavigationItem
  ) => {
    setSelectedItemKey(item.key);
    if (item.href) {
      if (selectedItemKey !== item.key) {
        navigate(item.href);
      }
    } else if (item.onClick) {
      item.onClick(event, item);
    } else {
      throw new Error("Neither href or onClick is defined");
    }
  };

  const items: NavigationItem[] = [
    {
      key: "topup",
      icon: <CreditCardOutlinedIcon />,
      text: "充值",
      href: "/topup",
    },
    {
      key: "referral",
      icon: <PersonAddAltOutlinedIcon />,
      text: "邀请",
      onClick: onReferralButtonClick,
    },
    {
      key: "logout",
      icon: <LogoutOutlinedIcon />,
      text: "退出",
      onClick: auth.signOut,
    },
  ];

  const mobileItemButtonSelectedStyles = (key: string) => {
    if (selectedItemKey === key) {
      return {
        ...styles.mobileItemButton,
        ...styles.mobileItemButtonSelectedColor,
      };
    } else {
      return {
        ...styles.mobileItemButton,
        ...styles.mobileItemButtonColor,
      };
    }
  };

  return (
    <List sx={styles.items} disablePadding>
      {items.map((item, index) => (
        <Box key={item.key}>
          <ListItem disableGutters disablePadding>
            <ListItemButton
              sx={mobileItemButtonSelectedStyles(item.key)}
              onClick={(event) => handleItemClick(event, item)}
              disableGutters
            >
              <ListItemIcon sx={styles.itemIcon}>{item.icon}</ListItemIcon>
              <ListItemText
                primary={
                  <Typography sx={styles.mobileItemText}>
                    {item.text}
                  </Typography>
                }
              />
            </ListItemButton>
          </ListItem>
          {index < items.length - 1 && <Divider sx={styles.divider} />}
        </Box>
      ))}
    </List>
  );
}

interface NavigationProps extends DrawerProps {
  navigationItemKey: NavigationItemKey;
}

export default function Navigation({ navigationItemKey }: NavigationProps) {
  const theme = useTheme();
  const styles = {
    desktopDrawer: {
      "& .MuiDrawer-paper": { border: "none" },
    },
    desktopItems: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
      background: theme.palette.grey[800],
    },
    mobileItems: {
      display: "flex",
      flexDirection: "row",
      background: theme.palette.grey[800],
      minHeight: `${BottomNavHeightMobile}px`,
    },
    desktopItemButton: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      height: "72px",
      width: "72px",
      borderRadius: "16px",
      "&:hover": {
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
      },
    },
    mobileItemButton: {
      "&.Mui-selected": {
        color: theme.palette.primary.main,
      },
    },
    desktopItemButtonColor: {
      background: theme.palette.grey[700],
      color: "#f5f5f5",
    },
    mobileItemButtonColor: {
      color: "#f5f5f5",
    },
    desktopItemButtonSelectedColor: {
      background: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    mobileItemButtonSelectedColor: {
      color: theme.palette.primary.main,
    },
    itemIcon: {
      minWidth: "auto",
      color: "inherit",
    },
    mobileItemText: {
      fontSize: "0.875rem",
    },
  };

  const [selectedItemKey, setSelectedItemKey] =
    useState<NavigationItemKey>(navigationItemKey);
  const [isReferralModalOpen, setIsReferralModalOpen] =
    useState<boolean>(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const auth = useAuth();
  const navigate = useNavigate();

  const handleItemClick = (
    event: React.MouseEvent<HTMLElement>,
    item: NavigationItem
  ) => {
    setSelectedItemKey(item.key);
    if (item.href) {
      if (selectedItemKey !== item.key) {
        navigate(item.href);
      }
    } else if (item.onClick) {
      item.onClick(event, item);
    } else {
      throw new Error("Neither href or onClick is defined");
    }
  };
  const handleProfileClick = (
    event: React.MouseEvent<HTMLElement>,
    item: NavigationItem
  ) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedItemKey(item.key);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const topItems: NavigationItem[] = [
    { key: "chat", icon: <ChatOutlinedIcon />, text: "聊天", href: "/chat" },
    {
      key: "document",
      icon: <DescriptionOutlinedIcon />,
      text: "文档",
      href: "/document-query",
    },
    { key: "ppt", icon: <SlideshowOutlinedIcon />, text: "PPT", href: "/ppt" },
  ];
  const bottomItems: NavigationItem[] = [
    {
      key: "topup",
      icon: <CreditCardOutlinedIcon />,
      text: "充值",
      href: "/topup",
    },
    {
      key: "referral",
      icon: <PersonAddAltOutlinedIcon />,
      text: "邀请",
      onClick: () => setIsReferralModalOpen(true),
    },
    {
      key: "logout",
      icon: <LogoutOutlinedIcon />,
      text: "退出",
      onClick: auth.signOut,
    },
  ];
  const mobileItems: NavigationItem[] = [
    { key: "chat", icon: <ChatOutlinedIcon />, text: "聊天", href: "/chat" },
    {
      key: "document",
      icon: <DescriptionOutlinedIcon />,
      text: "文档",
      href: "/document-query",
    },
    { key: "ppt", icon: <SlideshowOutlinedIcon />, text: "PPT", href: "/ppt" },
    {
      key: "profile",
      icon: <PermIdentityOutlinedIcon />,
      text: "账户",
      onClick: handleProfileClick,
    },
  ];
  const desktopItemButtonSelectedStyles = (key: string) => {
    if (selectedItemKey === key) {
      return {
        ...styles.desktopItemButton,
        ...styles.desktopItemButtonSelectedColor,
      };
    } else {
      return {
        ...styles.desktopItemButton,
        ...styles.desktopItemButtonColor,
      };
    }
  };

  return (
    <>
      {isReferralModalOpen && (
        <Referral onClose={() => setIsReferralModalOpen(false)} />
      )}
      {isMenuOpen && (
        <Popover
          open={isMenuOpen}
          anchorEl={menuAnchorEl}
          onClose={handleMenuClose}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          transformOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <ProfileMenu
            onReferralButtonClick={() => setIsReferralModalOpen(true)}
          />
        </Popover>
      )}
      {isMobile && (
        <BottomNavigation
          showLabels
          value={selectedItemKey}
          onChange={(_, key) => setSelectedItemKey(key)}
          sx={styles.mobileItems}
        >
          {mobileItems.map((item) => (
            <BottomNavigationAction
              key={item.key}
              label={item.text}
              icon={item.icon}
              value={item.key}
              onClick={(event) => handleItemClick(event, item)}
              sx={styles.mobileItemButton}
            />
          ))}
        </BottomNavigation>
      )}
      {!isMobile && (
        <Drawer
          variant="permanent"
          anchor="left"
          sx={styles.desktopDrawer}
          open
        >
          <Box sx={styles.desktopItems}>
            <List>
              {topItems.map((item) => (
                <ListItem key={item.key}>
                  <ListItemButton
                    sx={desktopItemButtonSelectedStyles(item.key)}
                    onClick={(event) => handleItemClick(event, item)}
                  >
                    <ListItemIcon sx={styles.itemIcon}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
            <List>
              {bottomItems.map((item) => (
                <ListItem key={item.key}>
                  <ListItemButton
                    sx={desktopItemButtonSelectedStyles(item.key)}
                    onClick={(event) => handleItemClick(event, item)}
                  >
                    <ListItemIcon sx={styles.itemIcon}>
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText primary={item.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </Box>
        </Drawer>
      )}
    </>
  );
}
