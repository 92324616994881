import { useAuth } from "../../AuthProvider";
import { ClientUrl } from "../../config";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import { IconButton, Modal, TextField, Tooltip, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useRef } from "react";

interface ReferralProps {
  onClose: () => void;
}

export default function Referral({ onClose }: ReferralProps) {
  const theme = useTheme();
  const styles = {
    root: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      maxWidth: "400px",
      minWidth: "344px",
      display: "flex",
      flexDirection: "column",
      borderRadius: "40px",
      background: "#ffffff",
      padding: theme.spacing(5),
      gap: theme.spacing(3),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3),
        gap: theme.spacing(2),
      },
    },
    icon: {
      width: "80px",
      height: "80px",
    },
    codeDisplay: {
      display: "flex",
      justifyContent: "center",
    },
  };

  const auth = useAuth();
  const inputRef = useRef<HTMLInputElement>(null);

  const handleCopyReferralContent = async () => {
    if (inputRef.current) {
      const referralUrl = `${ClientUrl}/signup?referral-code=${inputRef.current.value}`;
      const referralContent = `在学习与工作中，我们时常面临各类问题与疑惑，寻找答案的过程往往耗费大量时间与精力。为了帮助您更高效地解决问题，我们推出了知行无界——一款智能问答工具。

知行无界的核心功能：

聊天问答：无论您遇到学习、工作还是生活中的问题，知行无界都能为您提供及时、准确的解答。
文档问答：您可以上传文档，提出具体问题，知行无界将从文档中为您找到相关答案。
知行无界依托先进的GPT-4模型，提供丰富的知识库，帮助您轻松解决各类问题，让学习与工作变得更加高效。
简易PPT生成：您只需输入关键信息，知行无界即可自动生成PPT，助力您的演讲和汇报。

我们邀请您免费体验知行无界，感受智能问答带来的便利。
点击链接：${referralUrl}，立即开启您的智能助手之旅。

知行无界，让知识与行动无界限。
期待您的加入。

本文案由知行无界协助生成。`;
      try {
        await navigator.clipboard.writeText(referralContent);
      } catch (err) {
        console.error("Failed to copy text: ", err);
      }
    }
  };

  return (
    <Modal open={true} onClose={onClose}>
      <Box sx={styles.root}>
        <Box component="img" src="/referral.png" sx={styles.icon} />
        <Typography variant="h4">邀请</Typography>
        <Typography>
          通过您的邀请码注册的朋友将多获得5次免费使用的机会！如果您的朋友充值，您还有机会获得返现奖励。
        </Typography>
        <Box sx={styles.codeDisplay}>
          <TextField
            value={auth.user?.referralCode}
            variant="outlined"
            disabled
            inputRef={inputRef}
            InputProps={{
              endAdornment: (
                <Tooltip title="复制邀请文案">
                  <IconButton onClick={handleCopyReferralContent}>
                    <FileCopyOutlinedIcon />
                  </IconButton>
                </Tooltip>
              ),
              style: { width: "fit-content" },
            }}
          />
        </Box>
      </Box>
    </Modal>
  );
}
