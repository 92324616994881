import { UserNotAuthenticatedError } from "../../Errors";
import { getPowerPointHistory } from "../../services/PowerPointService";
import { PowerPointSummary } from "../../types";
import History from "../Common/History";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface PowerPointSideNavProps {
  newPowerPointCount: number;
  onGenerateNewPowerPoint: () => void;
  onHistoryClick: (queryId: string) => void;
}

export default function PowerPointSideNav({
  newPowerPointCount,
  onGenerateNewPowerPoint,
  onHistoryClick,
}: PowerPointSideNavProps) {
  const [powerpointHistory, setPowerpointHistory] = useState<
    PowerPointSummary[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const history = await getPowerPointHistory();
        setPowerpointHistory(history.history);
      } catch (err) {
        if (err instanceof UserNotAuthenticatedError) {
          navigate("/signin");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [newPowerPointCount, navigate]);

  const itemContents = powerpointHistory.map((history) => ({
    key: history.id,
    text: history.topic,
    onClick: () => onHistoryClick(history.id),
  }));

  return (
    <History
      newButtonText="生成新PPT"
      onNewButtonClick={onGenerateNewPowerPoint}
      itemIcon={<SlideshowOutlinedIcon />}
      itemContents={itemContents}
      isLoading={isLoading}
    />
  );
}
