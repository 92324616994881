import { useAuth } from "../../AuthProvider";
import { sendVerificationCode } from "../../services/AuthService";
import ErrorAlert from "../Alerts/ErrorAlert";
import SuccessAlert from "../Alerts/SuccessAlert";
import InputField from "../Common/InputField";
import VerificationCodeInput from "./VerificationCodeInput";
import {
  Box,
  Button,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FormEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SignUp() {
  const theme = useTheme();
  const styles = {
    root: {
      height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "url('/background-image.png')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(6),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
    },
    title: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    registerButton: {
      "&.Mui-disabled": {
        background: "#808080",
        color: theme.palette.text.secondary,
      },
    },
  };

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [referralCode, setReferralCode] = useState<string | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [isSuccessAlertOpen, setIsSuccessAlertOpen] = useState<boolean>(false);
  const [isErrorAlertOpen, setIsErrorAlertOpen] = useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const auth = useAuth();

  useEffect(() => {
    // Parse the query parameters from the current URL
    const urlParams = new URLSearchParams(window.location.search);
    // Set the state with the value of the "referral-code" query parameter
    setReferralCode(urlParams.get("referral-code"));
  }, []);

  const areAllInputFieldsValid = phoneNumber && verificationCode;

  const handlePhoneNumberInputBlur = () => {
    // Validate that the input contains exactly 11 numeric characters
    const isValidPhoneNumber = /^\d{11}$/.test(phoneNumber);
    setIsPhoneNumberValid(isValidPhoneNumber);
  };
  const handleSendVerificationCodeButtonClick = async () => {
    if (!phoneNumber) {
      return;
    }
    try {
      await sendVerificationCode(phoneNumber);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
        setIsErrorAlertOpen(true);
      }
    }
  };
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!areAllInputFieldsValid) {
      return;
    }
    try {
      await auth.signUp(phoneNumber, verificationCode, referralCode);
      setIsSuccessAlertOpen(true);
      setTimeout(() => navigate("/chat"), 5000);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
        setIsErrorAlertOpen(true);
      }
    }
  };

  return (
    <Box sx={styles.root}>
      <SuccessAlert
        open={isSuccessAlertOpen}
        message="注册成功。5秒后将跳转至聊天问答页面"
        onClose={() => setIsSuccessAlertOpen(false)}
      />
      {error && (
        <ErrorAlert
          open={isErrorAlertOpen}
          error={error.message}
          onClose={() => setIsErrorAlertOpen(false)}
        />
      )}
      <Box sx={styles.content}>
        <Box sx={styles.title}>
          <Typography variant={isMobile ? "h3" : "h2"} color="textSecondary">
            注册
          </Typography>
          <Typography variant={isMobile ? "body1" : "h5"} color="textSecondary">
            已有账号？
            <Link href="/signin">登录</Link>
          </Typography>
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
              <InputField
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                onBlur={handlePhoneNumberInputBlur}
                error={!isPhoneNumberValid}
                placeholder="手机号"
                errorText="手机号格式错误"
              />
            </Grid>
            <VerificationCodeInput
              verificationCode={verificationCode}
              disabled={!Boolean(phoneNumber) || !isPhoneNumberValid}
              onInputChange={(e) => setVerificationCode(e.target.value)}
              onClick={handleSendVerificationCodeButtonClick}
            />
            <Grid item xs={12}>
              <InputField
                value={referralCode || ""}
                onChange={(e) => setReferralCode(e.target.value)}
                placeholder="邀请码（选填）"
              />
            </Grid>
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!areAllInputFieldsValid}
                sx={styles.registerButton}
              >
                注册
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
