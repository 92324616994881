import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { Button, Tooltip } from "@mui/material";

interface FileDownloadableItemProps {
  fileUrl: string;
}

export default function FileDownloadableItem({
  fileUrl,
}: FileDownloadableItemProps) {
  const styles = {
    button: {
      display: "flex",
      alignItems: "center",
      maxWidth: "376px",
    },
  };

  const handleCardClick = () => {
    window.open(fileUrl, "_blank");
  };

  return (
    <Tooltip title="点击打开源文档">
      <Button sx={styles.button} variant="outlined" onClick={handleCardClick}>
        <FileDownloadOutlinedIcon />
      </Button>
    </Tooltip>
  );
}
