import { useAuth } from "../../AuthProvider";
import ErrorAlert from "../Alerts/ErrorAlert";
import CustomerService from "../CustomerService";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SignInWithPassword() {
  const styles = {
    box: {
      marginTop: 8,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
    lockIcon: {
      m: 1,
      bgcolor: "secondary.main",
    },
  };

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [rememberDevice, setRememberDevice] = useState<boolean>(false);
  const [showPassword, setShowPassword] = useState(false);
  const [error, setError] = useState<Error | null>(null);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [isCustomerServiceModalOpen, setIsCustomerServiceModalOpen] =
    useState<boolean>(false);

  const navigate = useNavigate();
  const auth = useAuth();

  const areAllInputFieldsValid = phoneNumber && password;
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!areAllInputFieldsValid) {
      return;
    }
    try {
      await auth.signInWithPassword(phoneNumber, password, rememberDevice);
      navigate("/chat");
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
        setIsAlertOpen(true);
      }
    }
  };
  const handleCustomerServiceLinkClick = () =>
    setIsCustomerServiceModalOpen(true);
  const handleCustomerServiceModalClose = () =>
    setIsCustomerServiceModalOpen(false);

  return (
    <Container>
      {error && (
        <ErrorAlert
          open={isAlertOpen}
          error={error.message}
          onClose={() => setIsAlertOpen(false)}
        />
      )}
      <Box sx={styles.box}>
        <Avatar sx={styles.lockIcon}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          登录
        </Typography>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="phoneNumber"
            label="手机号"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            label="密码"
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={password}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={(e) => e.preventDefault()}
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
            onChange={(e) => setPassword(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                value={rememberDevice}
                color="primary"
                onChange={(e) => setRememberDevice(e.target.checked)}
              />
            }
            label="点此14天内免登录"
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            disabled={!areAllInputFieldsValid}
            color="secondary"
            sx={{ mt: 3, mb: 2 }}
          >
            登录
          </Button>
          <Grid container>
            <Grid item xs>
              <Link
                href="#"
                variant="body2"
                onClick={handleCustomerServiceLinkClick}
              >
                联系客服
              </Link>
            </Grid>
            <Grid item>
              <Link href="/" variant="body2">
                还没有账号？点此注册
              </Link>
            </Grid>
          </Grid>
        </Box>
        <CustomerService
          open={isCustomerServiceModalOpen}
          onClose={handleCustomerServiceModalClose}
        />
      </Box>
    </Container>
  );
}
