import { getAuthToken } from "../AuthProvider";
import { handleServerError } from "../Errors";
import { ServerUrl } from "../config";
import {
  GeneratePPTResponse,
  PowerPoint,
  PowerPointGenerationHistory,
} from "../types";

export const getPowerPointHistory = async () => {
  const response = await fetch(`${ServerUrl}/powerpoint-history`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }
  const history = await response.json();
  return history as PowerPointGenerationHistory;
};

export const getPowerPoint = async (powerpointId: string) => {
  const response = await fetch(`${ServerUrl}/powerpoints/${powerpointId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }
  const query = await response.json();
  return query as PowerPoint;
};

export const generatePowerPoint = async (
  topic: string,
  numSlides: number,
  language: string,
  modelType: string
) => {
  const payload = {
    topic,
    numSlides,
    language,
    modelType,
  };
  const response = await fetch(`${ServerUrl}/generate-ppt`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const resp = await response.json();
  return resp as GeneratePPTResponse;
};
