import {
  getUser,
  signInWithPassword,
  signInWithVerificationCode,
  signUp as signUpService,
} from "./services/AuthService";
import { User } from "./types";
import { CircularProgress } from "@mui/material";
import Box from "@mui/material/Box";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

interface AuthContextType {
  user: User | null;
  signUp: (
    phoneNumber: string,
    verificationCode: string,
    referralCode: string | null
  ) => void;
  signInWithPassword: (
    phoneNumber: string,
    password: string,
    rememberDevice: boolean
  ) => void;
  signInWithVerificationCode: (
    phoneNumber: string,
    verificationCode: string
  ) => void;
  signOut: () => void;
}

interface AuthProviderProps {
  children?: ReactNode;
}

const AuthContext = createContext<AuthContextType | null>(null);
const AuthTokenKey = "token";

export function AuthProvider({ children }: AuthProviderProps) {
  const styles = {
    loadingSpinnerContainer: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100vh",
    },
  };

  const [user, setUser] = useState<User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUser();
      setUser(user);
      setIsLoading(false);
    };
    fetchUser();
  }, []);

  const signUp = async (
    phoneNumber: string,
    verificationCode: string,
    referralCode: string | null
  ) => {
    const { user, token } = await signUpService(
      phoneNumber,
      verificationCode,
      referralCode
    );
    localStorage.setItem(AuthTokenKey, token);
    setUser(user);
  };

  const logInWithPassword = async (
    phoneNumber: string,
    password: string,
    rememberDevice: boolean
  ) => {
    const { user, token } = await signInWithPassword(phoneNumber, password);
    localStorage.setItem(AuthTokenKey, token);
    setUser(user);
  };

  const logInWithVerificationCode = async (
    phoneNumber: string,
    verificationCode: string
  ) => {
    const { user, token } = await signInWithVerificationCode(
      phoneNumber,
      verificationCode
    );
    localStorage.setItem(AuthTokenKey, token);
    setUser(user);
  };

  const logOut = () => {
    localStorage.removeItem(AuthTokenKey);
    setUser(null);
    navigate("/signin");
  };

  if (isLoading) {
    return (
      <Box sx={styles.loadingSpinnerContainer}>
        <CircularProgress />
      </Box>
    );
  }

  const value = {
    user,
    signUp,
    signInWithPassword: logInWithPassword,
    signInWithVerificationCode: logInWithVerificationCode,
    signOut: logOut,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export function useAuth() {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
}

export function RequireAuth({ children }: { children: JSX.Element }) {
  let auth = useAuth();
  let location = useLocation();

  if (!auth.user) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/signin" state={{ from: location }} replace />;
  }

  return children;
}

export function getAuthToken() {
  return localStorage.getItem(AuthTokenKey);
}
