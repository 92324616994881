import { grey } from "@mui/material/colors";
import { createTheme } from "@mui/material/styles";

// A custom theme for this app
const theme = createTheme({
  palette: {
    primary: {
      main: "#24ff7b",
      contrastText: "#0d0d0d",
    },
    secondary: {
      main: "#8a91ff",
      contrastText: "#1d00d4",
    },
    error: {
      main: "#f40058",
    },
    success: {
      main: "#24ff7b",
    },
    grey: {
      50: "#fafafa",
      100: "#d9d9d9",
      200: "#a7a7a7",
      300: "#909090",
      400: "#757575",
      500: "#5b5b5b",
      600: "#414141",
      700: "#383838",
      800: "#272727",
      900: "#1a1a1a",
    },
    text: {
      primary: "#0d0d0d",
      secondary: "#f3f3f3",
    },
    background: {
      paper: grey[50],
    },
  },
  typography: {
    fontFamily: '"DM Mono", monospace',
    fontWeightRegular: 500,
    h2: {
      fontSize: "4rem",
    },
    h3: {
      fontSize: "2.5rem",
    },
    h4: {
      fontSize: "2rem",
    },
    h5: {
      fontSize: "1.5rem",
    },
  },
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "24px",
          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
            border: "1px solid #0d0d0d",
          },
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 500,
          fontSize: "1rem",
          border: "1px solid #c8c8c8",
          borderRadius: "80px",
          height: "56px",
          boxShadow: "none",
          "&:hover": {
            boxShadow: "none",
          },
        },
        outlined: {
          color: "#0d0d0d",
        },
      },
    },
  },
});

export default theme;
