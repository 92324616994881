import {
  FormControl,
  FormHelperText,
  OutlinedInput,
  OutlinedInputProps,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import React from "react";

interface InputFieldProps extends OutlinedInputProps {
  errorText?: string;
}

export default function InputField({
  value,
  onChange,
  onBlur,
  error,
  placeholder,
  errorText,
}: InputFieldProps) {
  const theme = useTheme();
  const styles = {
    form: {
      width: "100%",
    },
    inputField: {
      background: "#181818",
      border: "1px solid #4d4d4d",
      borderRadius: "24px",
      "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffffff",
        boxShadow: "0px 0px 25px 0px #ffffff70",
      },
      "&.Mui-error .MuiOutlinedInput-notchedOutline": {
        borderColor: "#4d4d4d",
      },
      "&.Mui-error.Mui-focused .MuiOutlinedInput-notchedOutline": {
        borderColor: "#ffffff",
        boxShadow: "0px 0px 25px 0px #ffffff70",
      },
      "& .MuiInputBase-input": {
        fontWeight: 500,
        color: theme.palette.background.paper,
        "&::placeholder": {
          fontWeight: 500,
          color: theme.palette.background.paper,
          opacity: 1,
        },
      },
    },
    helperText: {
      color: theme.palette.error.main,
    },
  };

  return (
    <Box>
      <FormControl sx={styles.form}>
        <OutlinedInput
          fullWidth
          id="phone-number"
          placeholder={value ? "" : placeholder}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          error={error}
          sx={styles.inputField}
        />
        <FormHelperText sx={styles.helperText}>
          {error ? errorText : " "}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
