import { UserNotAuthenticatedError } from "../../Errors";
import {
  BottomNavHeightMobile,
  GPT35,
  HistoryDrawerWidthDesktop,
  ModelType,
  NavigationDrawerWidthDesktop,
} from "../../constants";
import {
  getConversation,
  getScenarios,
  sendChatMessage,
} from "../../services/ChatService";
import { Message, ScenarioSummary } from "../../types";
import Copyright from "../Common/Copyright";
import MessageDisplay from "../Common/MessageDisplay";
import ModelToggle from "../Common/ModelToggle";
import Navigation from "../Common/Navigation";
import UserInput from "../Common/UserInput";
import ChatSideNav from "./ChatSideNav";
import Scenarios from "./Scenarios";
import AddIcon from "@mui/icons-material/Add";
import { Button, Popover, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Chat() {
  const theme = useTheme();
  const styles = {
    root: {
      minHeight: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    sideNav: {
      [theme.breakpoints.down("sm")]: {
        position: "sticky",
        top: 0,
        zIndex: 1,
      },
    },
    chatArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flexGrow: 1,
      ml: `${NavigationDrawerWidthDesktop + HistoryDrawerWidthDesktop}px`,
      padding: "0 56px",
      background: theme.palette.background.paper,
      [theme.breakpoints.down("sm")]: {
        ml: 0,
        padding: "0 16px",
      },
    },
    modelToggle: {
      position: "sticky",
      top: 0,
      pt: theme.spacing(2),
      width: "100%",
      background: "inherit",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
    },
    modelButton: {
      borderRadius: 0,
      background: theme.palette.grey[50],
    },
    modelButtonSelected: {
      borderRadius: 0,
      background: theme.palette.primary.main,
    },
    messageDisplay: {
      flexGrow: 1,
      width: "100%",
    },
    loadScenarioButton: {
      width: "176px",
    },
    scenarios: {
      ".MuiPaper-root": {
        border: `1px solid ${theme.palette.grey[100]}`,
        borderRadius: "24px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        background: "#ffffff",
        padding: theme.spacing(3),
        boxShadow: "none",
        maxWidth: "100vh",
        maxHeight: "80vh",
        [theme.breakpoints.down("sm")]: {
          maxWidth: "50vh",
          maxHeight: "55vh",
        },
      },
      top: `-${theme.spacing(1)}`,
    },
    userInput: {
      position: "sticky",
      bottom: 0,
      pb: theme.spacing(2),
      width: "100%",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      gap: theme.spacing(2),
      background: "inherit",
      [theme.breakpoints.down("sm")]: {
        pb: theme.spacing(1),
        gap: theme.spacing(1),
        bottom: `${BottomNavHeightMobile}px`,
      },
    },
    nav: {
      [theme.breakpoints.down("sm")]: {
        position: "sticky",
        bottom: 0,
        zIndex: 1,
      },
    },
  };

  const [conversationId, setConversationId] = useState<string | null>(null);
  const [modelType, setModelType] = useState<ModelType>(GPT35);
  const [selectedScenario, setSelectedScenario] =
    useState<ScenarioSummary | null>(null);
  const [scenarios, setScenarios] = useState<ScenarioSummary[]>([]);
  const [messages, setMessages] = useState<Message[]>([]);
  const [isGeneratingMessage, setIsGeneratingMessage] =
    useState<boolean>(false);
  const [isLoadingConversation, setIsLoadingConversation] =
    useState<boolean>(false);
  const [newConversationCount, setNewConversationCount] = useState<number>(0);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);
  const isMenuOpen = Boolean(menuAnchorEl);
  const navigate = useNavigate();

  const handleStartNewConversation = () => {
    setConversationId(null);
    setMessages([]);
    setSelectedScenario(null);
  };
  const handleConversationClick = async (conversationId: string) => {
    setIsLoadingConversation(true);
    const conversation = await getConversation(conversationId);
    setConversationId(conversationId);
    setMessages(conversation.messages);
    setIsLoadingConversation(false);
  };
  const handleLoadScenarioButtonClick = (
    event: React.MouseEvent<HTMLElement>
  ) => {
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };
  const handleScenarioClick = (scenario: ScenarioSummary) => {
    setSelectedScenario(scenario);
    handleMenuClose();
    const scenarioMessage = {
      text: scenario.description,
      role: "assistant",
    } as Message;
    setMessages([...messages, scenarioMessage]);
  };
  const handleUserInputSubmission = async (message: string) => {
    setIsGeneratingMessage(true);
    const userMessage = { role: "user", text: message } as Message;
    const chatGPTLoadingMessage = {
      role: "assistant",
      text: "回答生成中，可能会花费几分钟的时间，请稍候。如您使用手机登录，请勿在此期间切换应用，否则链接可能会中断。",
    } as Message;
    const newMessages = [...messages, userMessage, chatGPTLoadingMessage];
    setMessages(newMessages);
    try {
      const chatResponse = await sendChatMessage(
        message,
        modelType,
        conversationId,
        selectedScenario !== null ? selectedScenario.id : null
      );
      setMessages([...newMessages.slice(0, -1), chatResponse.message]);
      if (conversationId === null) {
        setNewConversationCount(newConversationCount + 1);
      }
      setConversationId(chatResponse.conversationId);
    } catch (err) {
      if (err instanceof UserNotAuthenticatedError) {
        navigate("/signin");
      }
      const errorMessage = {
        role: "assistant",
        text: (err as Error).message,
      } as Message;
      setMessages([...newMessages.slice(0, -1), errorMessage]);
    } finally {
      setIsGeneratingMessage(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const scenarios = await getScenarios();
        setScenarios(scenarios.scenarios);
      } catch (err) {
        if (err instanceof UserNotAuthenticatedError) {
          navigate("/signin");
        }
      } finally {
      }
    };
    fetchData();
  }, [navigate]);

  return (
    <Box sx={styles.root}>
      <Box sx={styles.sideNav}>
        <ChatSideNav
          newConversationCount={newConversationCount}
          onStartNewConversation={handleStartNewConversation}
          onConversationClick={handleConversationClick}
        />
      </Box>
      <Box sx={styles.chatArea}>
        <Box sx={styles.modelToggle}>
          <ModelToggle modelType={modelType} onModelSelect={setModelType} />
        </Box>
        <Box sx={styles.messageDisplay}>
          <MessageDisplay
            isLoading={isLoadingConversation}
            messages={messages}
          />
        </Box>
        {isMenuOpen && (
          <Popover
            open={isMenuOpen}
            anchorEl={menuAnchorEl}
            onClose={handleMenuClose}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            transformOrigin={{ vertical: "bottom", horizontal: "center" }}
            sx={styles.scenarios}
          >
            <Scenarios
              scenarios={scenarios}
              onScenarioClick={handleScenarioClick}
            />
          </Popover>
        )}
        <Box sx={styles.userInput}>
          <Button
            variant="outlined"
            startIcon={<AddIcon />}
            onClick={handleLoadScenarioButtonClick}
            sx={styles.loadScenarioButton}
          >
            场景加载
          </Button>
          <UserInput
            isGeneratingMessage={isGeneratingMessage}
            onSubmit={handleUserInputSubmission}
          />
          <Copyright />
        </Box>
      </Box>
      <Box sx={styles.nav}>
        <Navigation navigationItemKey="chat" />
      </Box>
    </Box>
  );
}
