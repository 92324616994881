import {
  HistoryDrawerWidthDesktop,
  HistoryDrawerWidthMobile,
  NavigationDrawerWidthDesktop,
} from "../../constants";
import AddIcon from "@mui/icons-material/Add";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import {
  AppBar,
  CircularProgress,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useState } from "react";

export interface HistoryItemContent {
  key: string;
  text: string | null;
  onClick: () => void;
}

interface HistoryProps {
  newButtonText: string;
  onNewButtonClick: () => void;
  itemIcon: React.ReactElement;
  itemContents: HistoryItemContent[];
  isLoading: boolean;
}

export default function History({
  newButtonText,
  onNewButtonClick,
  itemIcon,
  itemContents,
  isLoading,
}: HistoryProps) {
  const theme = useTheme();
  const styles = {
    root: {
      flexShrink: {
        sm: 0,
      },
    },
    appBar: {
      background: theme.palette.grey[800],
    },
    toolBar: {
      display: "flex",
      justifyContent: "space-between",
    },
    toolBarButton: {
      color: "#f5f5f5",
    },
    mobileDrawer: {
      display: { xs: "block", sm: "none" },
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        background: theme.palette.grey[700],
        width: `${HistoryDrawerWidthMobile}px`,
      },
    },
    desktopDrawer: {
      display: { xs: "none", sm: "block" },
      "& .MuiDrawer-paper": {
        boxSizing: "border-box",
        ml: `${NavigationDrawerWidthDesktop}px`,
        width: `${HistoryDrawerWidthDesktop}px`,
        background: theme.palette.grey[700],
      },
    },
    newButtonItem: {
      position: "sticky",
      top: 0,
      background: theme.palette.grey[700],
      zIndex: 1,
    },
    newButton: {
      border: "1px solid #4D4D4D",
      borderRadius: "24px",
      color: theme.palette.grey[50],
      "&:hover": {
        background: theme.palette.grey[800],
      },
    },
    newButtonIcon: {
      color: "inherit",
      "&:hover": {
        background: theme.palette.grey[800],
      },
    },
    historyLoading: {
      height: "100%",
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
    },
    historyItem: {
      padding: "0 16px",
      "&:hover": {
        background: theme.palette.grey[800],
      },
    },
    historyItemButton: {
      color: theme.palette.grey[50],
    },
    historyItemIcon: {
      color: "inherit",
    },
    historyText: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  };

  const [selectedItemKey, setSelectedItemKey] = useState<string | null>(null);
  const [isHistoryNavOpenOnMobile, setIsHistoryNavOpenOnMobile] =
    useState<boolean>(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const toggleHistoryNav = () => {
    setIsHistoryNavOpenOnMobile(!isHistoryNavOpenOnMobile);
  };
  const handleNewButtonClick = () => {
    setSelectedItemKey(null);
    onNewButtonClick();
  };
  const handleItemClick = (content: HistoryItemContent) => {
    setSelectedItemKey(content.key);
    content.onClick();
  };

  const items = (
    <>
      <List sx={styles.newButtonItem}>
        <ListItem key="button">
          <ListItemButton onClick={handleNewButtonClick} sx={styles.newButton}>
            <ListItemIcon sx={styles.newButtonIcon}>
              <AddIcon />
            </ListItemIcon>
            <ListItemText primary={newButtonText} />
          </ListItemButton>
        </ListItem>
      </List>
      {isLoading && (
        <Box sx={styles.historyLoading}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <List>
          {itemContents.map((content) => (
            <ListItem
              key={content.key}
              sx={{
                ...styles.historyItem,
                background:
                  selectedItemKey === content.key ? "#272727" : "inherit",
              }}
            >
              <ListItemButton
                onClick={() => handleItemClick(content)}
                sx={styles.historyItemButton}
              >
                <ListItemIcon sx={styles.historyItemIcon}>
                  {itemIcon}
                </ListItemIcon>
                <ListItemText
                  primary={content.text}
                  primaryTypographyProps={{ sx: styles.historyText }}
                />
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      )}
    </>
  );

  return (
    <>
      {isMobile && (
        <AppBar position="static" sx={styles.appBar}>
          <Toolbar sx={styles.toolBar}>
            <IconButton
              edge="start"
              sx={styles.toolBarButton}
              onClick={toggleHistoryNav}
            >
              <MenuOutlinedIcon />
            </IconButton>
            <IconButton
              edge="end"
              sx={styles.toolBarButton}
              onClick={onNewButtonClick}
            >
              <AddIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
      )}
      <Box sx={styles.root}>
        <Drawer
          variant="temporary"
          open={isHistoryNavOpenOnMobile}
          onClose={toggleHistoryNav}
          ModalProps={{
            keepMounted: true,
          }}
          sx={styles.mobileDrawer}
        >
          {items}
        </Drawer>
        <Drawer variant="permanent" sx={styles.desktopDrawer} open>
          {items}
        </Drawer>
      </Box>
    </>
  );
}
