import { ScenarioSummary } from "../../types";
import {
  Button,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";

interface ScenariosProps {
  scenarios: ScenarioSummary[];
  onScenarioClick: (scenario: ScenarioSummary) => void;
}

export default function Scenarios({
  scenarios,
  onScenarioClick,
}: ScenariosProps) {
  const theme = useTheme();
  const styles = {
    card: {
      borderRadius: "32px",
      display: "flex",
      flexDirection: "column",
      alignItems: "flex-start",
      gap: theme.spacing(2),
      padding: theme.spacing(2),
      minHeight: "184px",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        minHeight: "144px",
      },
    },
    description: {
      display: "-webkit-box",
      WebkitLineClamp: 2,
      WebkitBoxOrient: "vertical",
      overflow: "hidden",
      textOverflow: "ellipsis",
      height: "3em",
      [theme.breakpoints.down("sm")]: {
        WebkitLineClamp: 1,
      },
    },
    categoryLabel: {
      background: theme.palette.primary.main,
      borderRadius: "80px",
      padding: "4px 8px",
    },
  };

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <Grid container spacing={2}>
      {scenarios.map((scenario, index) => (
        <Grid key={index} item xs={12} sm={4}>
          <Button
            variant="outlined"
            sx={styles.card}
            onClick={() => onScenarioClick(scenario)}
          >
            <Typography variant={isMobile ? "h6" : "h5"} align="left">
              {scenario.title}
            </Typography>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              sx={styles.description}
              align="left"
            >
              {scenario.description}
            </Typography>
            <Typography
              variant={isMobile ? "body2" : "body1"}
              sx={styles.categoryLabel}
            >
              #{scenario.category}
            </Typography>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
}
