import Typography from "@mui/material/Typography";
import * as React from "react";

export default function Copyright() {
  const styles = {
    text: {
      fontSize: "0.75rem",
      color: "#979797",
    },
  };

  return (
    <Typography variant="body2" align="center" sx={styles.text}>
      {`版权所有 © 知行无界 ${new Date().getFullYear()}`}
    </Typography>
  );
}
