import { getAuthToken } from "../AuthProvider";
import { handleServerError } from "../Errors";
import { ServerUrl } from "../config";
import {
  DocumentQuery,
  DocumentQueryHistory,
  QueryWithDocumentResponse,
  UploadDocumentForQueryResponse,
} from "../types";

export const getDocumentQueryHistory = async () => {
  const response = await fetch(`${ServerUrl}/document-query-history`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }
  const history = await response.json();
  return history as DocumentQueryHistory;
};

export const getDocumentQuery = async (queryId: string) => {
  const response = await fetch(`${ServerUrl}/document-queries/${queryId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }
  const query = await response.json();
  return query as DocumentQuery;
};

export const uploadDocument = async (document: File) => {
  const bodyData = new FormData();
  bodyData.append("document", document);
  const response = await fetch(`${ServerUrl}/upload-document-for-query`, {
    method: "POST",
    headers: {
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: bodyData,
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const resp = await response.json();
  return resp as UploadDocumentForQueryResponse;
};

export const queryWithSources = async (
  message: string,
  queryId: string,
  modelType: string
) => {
  const messageCreatedAt = new Date();
  const payload = {
    text: message,
    createdAt: messageCreatedAt.toISOString(),
    queryId: queryId,
    modelType,
  };
  const response = await fetch(`${ServerUrl}/query-with-source`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const resp = await response.json();
  return resp as QueryWithDocumentResponse;
};
