import {
  NoValidUsagePlanFoundError,
  UserNotAuthenticatedError,
} from "../../Errors";
import {
  BottomNavHeightMobile,
  GPT35,
  HistoryDrawerWidthDesktop,
  ModelType,
  NavigationDrawerWidthDesktop,
} from "../../constants";
import {
  getDocumentQuery,
  queryWithSources,
  uploadDocument,
} from "../../services/DocumentQueryService";
import { Message } from "../../types";
import ErrorAlert from "../Alerts/ErrorAlert";
import SuccessAlert from "../Alerts/SuccessAlert";
import Copyright from "../Common/Copyright";
import MessageDisplay from "../Common/MessageDisplay";
import ModelToggle from "../Common/ModelToggle";
import Navigation from "../Common/Navigation";
import UserInput from "../Common/UserInput";
import DocumentQuerySideNav from "./DocumentQuerySideNav";
import FileDownloadButton from "./FileDownloadableItem";
import FileUploader from "./FileUploader";
import { CircularProgress, useTheme } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function DocumentQuery() {
  const theme = useTheme();
  const styles = {
    root: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      overflow: "auto",
    },
    sideNav: {
      [theme.breakpoints.down("sm")]: {
        position: "sticky",
        top: 0,
        zIndex: 999,
      },
    },
    loadingSpinner: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      ml: `${NavigationDrawerWidthDesktop + HistoryDrawerWidthDesktop}px`,
      height: "100%",
      [theme.breakpoints.down("sm")]: {
        ml: 0,
      },
    },
    queryArea: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      flexGrow: 1,
      ml: `${NavigationDrawerWidthDesktop + HistoryDrawerWidthDesktop}px`,
      p: "0 56px",
      background: theme.palette.background.paper,
      [theme.breakpoints.down("sm")]: {
        ml: 0,
        padding: "0 16px",
      },
    },
    fileUploadArea: {
      height: "100%",
      width: "100%",
      display: "flex",
      flexDirection: "column",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    fileUploadTitle: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      mt: theme.spacing(10),
      mb: theme.spacing(4),
      maxWidth: "264px",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        mt: theme.spacing(4),
        mb: theme.spacing(4),
      },
    },
    description: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    messageDisplay: {
      flexGrow: 1,
      width: "100%",
    },
    topSection: {
      position: "sticky",
      top: 0,
      pt: theme.spacing(2),
      width: "100%",
      background: "inherit",
      zIndex: 1,
      display: "flex",
      justifyContent: "center",
      gap: 1,
      [theme.breakpoints.down("sm")]: {
        top: "56px",
      },
    },
    userInput: {
      position: "sticky",
      bottom: 0,
      pb: theme.spacing(2),
      width: "100%",
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      background: "inherit",
      [theme.breakpoints.down("sm")]: {
        pb: theme.spacing(1),
        gap: theme.spacing(1),
        bottom: `${BottomNavHeightMobile}px`,
      },
    },
    nav: {
      [theme.breakpoints.down("sm")]: {
        position: "sticky",
        bottom: 0,
        zIndex: 1,
      },
    },
  };

  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const [isGeneratingMessage, setIsGeneratingMessage] =
    useState<boolean>(false);
  const [queryId, setQueryId] = useState<string>("");
  const [modelType, setModelType] = useState<ModelType>(GPT35);
  const [messages, setMessages] = useState<Message[]>([]);

  const [inputDocumentUrl, setInputDocumentUrl] = useState<string>("");
  const [isLoadingDocument, setIsLoadingDocument] = useState<boolean>(false);
  const [newDocumentCount, setNewDocumentCount] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);
  const navigate = useNavigate();

  const handleQueryWithNewDocument = () => {
    setUploadedFile(null);
    setQueryId("");
    setModelType(GPT35);
    setMessages([]);
    setInputDocumentUrl("");
  };
  const handleHistoryClick = async (queryId: string) => {
    setIsLoadingDocument(true);
    const documentQuery = await getDocumentQuery(queryId);
    setQueryId(queryId);
    setModelType(GPT35);
    setMessages(documentQuery.messages);
    setInputDocumentUrl(documentQuery.inputDocumentUrl);
    setIsLoadingDocument(false);
  };
  const isLoading = isProcessing || isLoadingDocument;
  const handleFileUploaded = (file: File) => {
    setUploadedFile(file);
  };
  const handleFileRemoved = () => {
    setUploadedFile(null);
  };
  const handleFileSubmission = async () => {
    if (uploadedFile === null) return;

    setIsProcessing(true);
    try {
      const { queryId, inputDocumentUrl } = await uploadDocument(uploadedFile);
      setQueryId(queryId);
      setInputDocumentUrl(inputDocumentUrl);
      setNewDocumentCount(newDocumentCount + 1);
      setIsAlertOpen(true);
    } catch (err) {
      if (err instanceof UserNotAuthenticatedError) {
        navigate("/signin");
      }
      if (err instanceof NoValidUsagePlanFoundError) {
        setIsAlertOpen(true);
        setError(err);
      }
    } finally {
      setIsProcessing(false);
    }
  };

  const handleUserInputSubmission = async (message: string) => {
    if (queryId === null) {
      return;
    }
    setIsGeneratingMessage(true);
    const userMessage = { role: "user", text: message } as Message;
    const chatGPTLoadingMessage = {
      role: "assistant",
      text: "回答生成中，可能会花费几分钟的时间，请稍候。如您使用手机登录，请勿在此期间切换应用，否则链接可能会中断。",
    } as Message;
    const newMessages = [...messages, userMessage, chatGPTLoadingMessage];
    setMessages(newMessages);
    try {
      const chatResponse = await queryWithSources(message, queryId, modelType);
      setMessages([...newMessages.slice(0, -1), chatResponse.message]);
    } catch (err) {
      if (err instanceof UserNotAuthenticatedError) {
        navigate("/signin");
      }
      const errorMessage = {
        role: "assistant",
        text: (err as Error).message,
      } as Message;
      setMessages([...newMessages.slice(0, -1), errorMessage]);
    } finally {
      setIsGeneratingMessage(false);
    }
  };

  return (
    <Box sx={styles.root}>
      {isAlertOpen && (
        <SuccessAlert
          open={isAlertOpen}
          message="上传成功，请开始问答"
          onClose={() => setIsAlertOpen(false)}
        ></SuccessAlert>
      )}
      {error && (
        <ErrorAlert
          open={isAlertOpen}
          error={error.message}
          onClose={() => setIsAlertOpen(false)}
        />
      )}
      <Box sx={styles.sideNav}>
        <DocumentQuerySideNav
          newDocumentCount={newDocumentCount}
          onQueryWithNewDocument={handleQueryWithNewDocument}
          onHistoryClick={handleHistoryClick}
        />
      </Box>
      {isLoading && (
        <Box sx={styles.loadingSpinner}>
          <CircularProgress />
          <Typography sx={{ mt: 1 }}>
            {`${isProcessing ? "处理中" : "读取中"}，请稍后……`}
          </Typography>
        </Box>
      )}
      {!isLoading && (
        <Box sx={styles.queryArea}>
          {!inputDocumentUrl && (
            <Box sx={styles.fileUploadArea}>
              <Box sx={styles.fileUploadTitle}>
                <Typography variant="h4">文档问答</Typography>
                <Typography variant="h6" sx={styles.description}>
                  可对上传的文档内容进行提问，总结，摘要等
                </Typography>
              </Box>
              <FileUploader
                uploadedFile={uploadedFile}
                shouldDisableFileRemoval={isProcessing}
                onFileUploaded={handleFileUploaded}
                onFileRemoved={handleFileRemoved}
                onFileSubmission={handleFileSubmission}
              />
            </Box>
          )}
          {inputDocumentUrl && (
            <>
              <Box sx={styles.topSection}>
                <ModelToggle
                  modelType={modelType}
                  onModelSelect={setModelType}
                />
                <FileDownloadButton fileUrl={inputDocumentUrl} />
              </Box>
              <Box sx={styles.messageDisplay}>
                <MessageDisplay
                  isLoading={isLoadingDocument}
                  messages={messages}
                />
              </Box>
            </>
          )}
          <Box sx={styles.userInput}>
            <UserInput
              isGeneratingMessage={isGeneratingMessage}
              onSubmit={handleUserInputSubmission}
              disabled={!Boolean(inputDocumentUrl)}
            />
            <Copyright />
          </Box>
        </Box>
      )}
      <Box sx={styles.nav}>
        <Navigation navigationItemKey="document" />
      </Box>
    </Box>
  );
}
