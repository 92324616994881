import { NavigationDrawerWidthDesktop } from "../../constants";
import Copyright from "../Common/Copyright";
import Navigation from "../Common/Navigation";
import TopUpButton from "./TopUpButton";
import UsagePlansDisplay from "./UsagePlansDisplay";
import CloseIcon from "@mui/icons-material/Close";
import {
  Grid,
  IconButton,
  Modal,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

interface QRCodeModalProps {
  open: boolean;
  url: string;
  title: string;
  onClose: () => void;
}

function QRCodeModal({ open, url, title, onClose }: QRCodeModalProps) {
  const theme = useTheme();
  const styles = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      borderRadius: "40px",
      background: "#ffffff",
      width: "300px",
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      padding: theme.spacing(3),
      gap: theme.spacing(3),
    },
    title: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    image: {
      height: "240px",
      width: "240px",
    },
  };

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modal}>
        <Box sx={styles.title}>
          <Typography variant="h4">{title}</Typography>
          <IconButton color="inherit" onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
        <Box component="img" src={url} sx={styles.image} />
      </Box>
    </Modal>
  );
}

export default function TopUp() {
  const theme = useTheme();
  const styles = {
    root: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    content: {
      ml: `${NavigationDrawerWidthDesktop}px`,
      padding: "56px 56px 0 56px",
      overflow: "auto",
      background: theme.palette.background.paper,
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        ml: 0,
        padding: "16px 16px 0 16px",
      },
    },
    topUpCard: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      background: "#ffffff",
      gap: theme.spacing(5),
      padding: theme.spacing(10),
      borderRadius: "40px",
      minHeight: "352px",
      [theme.breakpoints.down("sm")]: {
        gap: theme.spacing(4),
        padding: theme.spacing(4),
      },
    },
    topUpTitle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      gap: theme.spacing(2),
    },
    addGroupCard: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      background: "#ffffff",
      gap: theme.spacing(5),
      padding: "56px 80px",
      borderRadius: "40px",
      minHeight: "352px",
      [theme.breakpoints.down("sm")]: {
        gap: theme.spacing(4),
        padding: theme.spacing(4),
        flexDirection: "column",
      },
    },
    addGroupTitle: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      gap: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    qrCodeDisplay: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      alignItems: "center",
      padding: theme.spacing(4),
      background: "#f0f0f0",
      borderRadius: "32px",
      gap: theme.spacing(1),
    },
    qrCode: {
      width: "160px",
      height: "160px",
      [theme.breakpoints.down("xl")]: {
        width: "80px",
        height: "80px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "160px",
        height: "160px",
      },
    },
    planCard: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      background: "#ffffff",
      gap: theme.spacing(5),
      padding: "56px 80px",
      borderRadius: "40px",
      [theme.breakpoints.down("sm")]: {
        gap: theme.spacing(4),
        padding: theme.spacing(4),
      },
    },
    copyright: {
      position: "sticky",
      bottom: 0,
      pb: theme.spacing(2),
      width: "100%",
      background: "inherit",
      [theme.breakpoints.down("sm")]: {
        pb: theme.spacing(1),
      },
    },
  };

  const [isQRCodeOpen, setIsQRCodeOpen] = useState<boolean>(false);
  const [qrCodeTitle, setQRCodeTitle] = useState<string>("");
  const [qrCodeImgUrl, setQRCodeUrl] = useState<string>("");
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const onQRCodeImgClick = (url: string, title: string) => {
    setIsQRCodeOpen(true);
    setQRCodeUrl(url);
    setQRCodeTitle(title);
  };
  const onQRCodeModalClose = () => {
    setIsQRCodeOpen(false);
    setQRCodeUrl("");
    setQRCodeTitle("");
  };

  return (
    <Box sx={styles.root}>
      <Box sx={styles.content}>
        <QRCodeModal
          open={isQRCodeOpen}
          title={qrCodeTitle}
          url={qrCodeImgUrl}
          onClose={onQRCodeModalClose}
        />
        <Grid container spacing={isMobile ? 2 : 3}>
          <Grid item lg={5} xs={12}>
            <Box sx={styles.topUpCard}>
              <Typography variant="h4" sx={styles.topUpTitle}>
                喜欢我们的产品？
                <Typography>立即充值成为会员！</Typography>
              </Typography>
              <TopUpButton fullWidth />
            </Box>
          </Grid>
          <Grid item lg={7} xs={12}>
            <Box sx={styles.addGroupCard}>
              <Typography variant="h4" sx={styles.addGroupTitle}>
                加群交流
                <Typography>随时交流提问技巧，提供产品反馈</Typography>
              </Typography>
              <Box sx={styles.qrCodeDisplay}>
                <Box
                  component="img"
                  src="/group-qr-code.jpg"
                  alt=""
                  sx={styles.qrCode}
                  onClick={() =>
                    onQRCodeImgClick("/group-qr-code.jpg", "微信交流群")
                  }
                />
                <Typography>微信交流群</Typography>
              </Box>
              <Box sx={styles.qrCodeDisplay}>
                <Box
                  component="img"
                  src="/QR-code.jpg"
                  alt=""
                  sx={styles.qrCode}
                  onClick={() => onQRCodeImgClick("/QR-code.jpg", "开发者微信")}
                />
                <Typography>开发者微信</Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box sx={styles.planCard}>
              <Typography variant="h4">套餐详情</Typography>
              <UsagePlansDisplay />
            </Box>
          </Grid>
        </Grid>
        <Box sx={styles.copyright}>
          <Copyright />
        </Box>
      </Box>
      <Navigation navigationItemKey="topup" />
    </Box>
  );
}
