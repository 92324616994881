import { Alert, Snackbar } from "@mui/material";
import React from "react";

interface ErrorAlertProps {
  open: boolean;
  error: string;
  onClose: () => void;
}

export default function ErrorAlert({ open, error, onClose }: ErrorAlertProps) {
  if (!error) {
    return <></>;
  }
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert variant="filled" severity="error">
        {error}
      </Alert>
    </Snackbar>
  );
}
