import { GPT35, GPT4, ModelType } from "../../constants";
import { Box, Popover, Typography, useTheme } from "@mui/material";
import React from "react";

interface ModelToggleProps {
  modelType: string;
  onModelSelect: (modelType: ModelType) => void;
}

export default function ModelToggle({
  modelType,
  onModelSelect,
}: ModelToggleProps) {
  const theme = useTheme();
  const styles = {
    container: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      width: "272px",
      height: "56px",
      padding: theme.spacing(1),
      gap: "4px",
      borderRadius: "24px",
      border: "1px solid #c8c8c8",
      background: theme.palette.grey[100],
    },
    modelSelected: {
      display: "flex",
      width: "100%",
      height: "100%",
      padding: theme.spacing(1),
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "16px",
      background: "#ffffff",
      "&:hover": {
        cursor: "pointer",
      },
    },
    modelUnselected: {
      display: "flex",
      width: "100%",
      height: "100%",
      padding: theme.spacing(1),
      justifyContent: "center",
      alignItems: "center",
      borderRadius: "16px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    modelDescription: {
      pointerEvents: "none",
      "& .MuiPaper-root": {
        boxShadow: "none",
        border: "1px solid #c8c8c8",
        borderRadius: "24px",
      },
    },
    modelDescriptionText: {
      p: theme.spacing(2),
    },
  };

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [modelHovered, setModelHovered] = React.useState<ModelType | null>(
    null
  );
  const open = Boolean(anchorEl);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    model: ModelType
  ) => {
    setModelHovered(model);
    setAnchorEl(document.getElementById("container"));
  };
  const handlePopoverClose = () => {
    setModelHovered(null);
    setAnchorEl(null);
  };

  return (
    <Box>
      <Box id="container" sx={styles.container}>
        <Box
          onClick={() => onModelSelect(GPT35)}
          sx={
            modelType === GPT35 ? styles.modelSelected : styles.modelUnselected
          }
          onMouseEnter={(event) => handlePopoverOpen(event, GPT35)}
          onMouseLeave={handlePopoverClose}
        >
          <Typography
            color={
              modelType === GPT35 ? "textPrimary" : `${theme.palette.grey[500]}`
            }
          >
            GPT-3.5
          </Typography>
        </Box>
        <Box
          onClick={() => onModelSelect(GPT4)}
          sx={
            modelType === GPT4 ? styles.modelSelected : styles.modelUnselected
          }
          onMouseEnter={(event) => handlePopoverOpen(event, GPT4)}
          onMouseLeave={handlePopoverClose}
        >
          <Typography
            color={
              modelType === GPT4 ? "textPrimary" : `${theme.palette.grey[500]}`
            }
          >
            GPT-4
          </Typography>
        </Box>
      </Box>
      <Popover
        sx={styles.modelDescription}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
        elevation={0}
        style={{ transform: "translateY(8px)" }}
      >
        <Typography sx={styles.modelDescriptionText}>
          {modelHovered === GPT35 &&
            "该模型处理速度更快、可理解和生成的内容更长"}
          {modelHovered === GPT4 && "该模型创造力与推理能力更强"}
        </Typography>
      </Popover>
    </Box>
  );
}
