import { ReactComponent as ChatGPTIcon } from "../../images/chat-gpt.svg";
import { Message } from "../../types";
import {
  Avatar,
  CircularProgress,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useRef } from "react";
import { ReactMarkdown } from "react-markdown/lib/react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { dracula } from "react-syntax-highlighter/dist/esm/styles/prism";

interface ChatAreaProps {
  isLoading: boolean;
  messages: Message[];
}

export default function MessageDisplay({ isLoading, messages }: ChatAreaProps) {
  const theme = useTheme();
  const styles = {
    root: {
      height: "100%",
    },
    loadingSpinner: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
    },
    messages: {
      height: "100%",
      width: "100%",
      overflow: "auto",
      backgroundColor: "background.paper",
    },
    message: {
      padding: "8px 0",
    },
    gptAvatar: {
      background: theme.palette.primary.main,
    },
    gptLogo: {
      fill: theme.palette.primary.contrastText,
      width: "75%",
      height: "75%",
    },
    userAvatar: {
      background: theme.palette.secondary.main,
      color: theme.palette.secondary.contrastText,
    },
  };

  const lastMessageRef = useRef<HTMLLIElement | null>(null);

  useEffect(() => {
    if (lastMessageRef.current) {
      lastMessageRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const isModelMessage = (message: Message) => {
    return message.role === "assistant";
  };

  return (
    <Box sx={styles.root}>
      {isLoading && (
        <Box sx={styles.loadingSpinner}>
          <CircularProgress />
        </Box>
      )}
      {!isLoading && (
        <List sx={styles.messages}>
          {messages.map((message, idx) => (
            <Box key={`message-${idx}`}>
              <ListItem
                ref={idx === messages.length - 1 ? lastMessageRef : null}
                alignItems="flex-start"
                sx={styles.message}
              >
                <ListItemAvatar>
                  {isModelMessage(message) && (
                    <Avatar alt="知" sx={styles.gptAvatar}>
                      <Box component={ChatGPTIcon} sx={styles.gptLogo} />
                    </Avatar>
                  )}
                  {!isModelMessage(message) && (
                    <Avatar sx={styles.userAvatar}>我</Avatar>
                  )}
                </ListItemAvatar>
                <ListItemText>
                  <ReactMarkdown
                    children={message.text}
                    components={{
                      code({
                        node,
                        inline,
                        className,
                        children,
                        style,
                        ...props
                      }) {
                        const match = /language-(\w+)/.exec(className || "");
                        return !inline && match ? (
                          <SyntaxHighlighter
                            children={String(children).replace(/\n$/, "")}
                            style={dracula}
                            language={match[1]}
                            PreTag="div"
                            {...props}
                          />
                        ) : (
                          <code className={className} {...props}>
                            {children}
                          </code>
                        );
                      },
                    }}
                  />
                </ListItemText>
              </ListItem>
              <Divider />
            </Box>
          ))}
          <ListItem>
            <Box sx={{ height: "50px" }} />
          </ListItem>
        </List>
      )}
    </Box>
  );
}
