import { UserNotAuthenticatedError } from "../Errors";
import { getCustomerSupport } from "../services/SupportService";
import { Button, CircularProgress, Modal } from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface CustomerServiceProps {
  open: boolean;
  onClose: () => void;
}

export default function CustomerService({
  open,
  onClose,
}: CustomerServiceProps) {
  const styles = {
    modal: {
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: 400,
      bgcolor: "background.paper",
      boxShadow: 24,
      pt: 2,
      px: 4,
      pb: 3,
      display: "flex",
      flexDirection: "column",
    },
  };

  const [qrCodeUrl, setQRCodeUrl] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const url = await getCustomerSupport();
        setQRCodeUrl(url);
      } catch (err) {
        if (err instanceof UserNotAuthenticatedError) {
          navigate("/signin");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [navigate]);

  return (
    <Modal open={open} onClose={onClose}>
      <Box sx={styles.modal}>
        <Typography variant="h6">扫码咨询客服</Typography>
        {isLoading && <CircularProgress />}
        {!isLoading && <img src={qrCodeUrl} alt="" />}
        <Button onClick={onClose} variant="contained" color="secondary">
          关闭
        </Button>
      </Box>
    </Modal>
  );
}
