import { Alert, Snackbar } from "@mui/material";
import React from "react";

interface SuccessAlertProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

export default function SuccessAlert({
  open,
  message,
  onClose,
}: SuccessAlertProps) {
  return (
    <Snackbar
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      open={open}
      autoHideDuration={5000}
      onClose={onClose}
    >
      <Alert variant="filled" severity="success">
        {message}
      </Alert>
    </Snackbar>
  );
}
