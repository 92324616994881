import { ReactComponent as AlipayIcon } from "../../images/alipay.svg";
import { ReactComponent as WeChatIcon } from "../../images/wechat.svg";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  ButtonProps,
  Grid,
  IconButton,
  Modal,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  display: "flex",
  flexDirection: "column",
  borderRadius: "40px",
  background: "#ffffff",
};

function QRCodeModal() {
  const theme = useTheme();
  const styles = {
    buttons: {
      display: "flex",
      justifyContent: "center",
      gap: theme.spacing(4),
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        gap: theme.spacing(2),
      },
    },
    modal: {
      ...modalStyle,
      width: "300px",
      gap: theme.spacing(2),
      padding: theme.spacing(5),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3),
      },
    },
    modalTitle: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
  };
  const [wechatOpen, setWeChatOpen] = useState<boolean>(false);
  const [alipayOpen, setAlipayOpen] = useState<boolean>(false);

  const handleWechatOpen = () => {
    setWeChatOpen(true);
  };
  const handleAlipayOpen = () => {
    setAlipayOpen(true);
  };
  const handleClose = () => {
    setWeChatOpen(false);
    setAlipayOpen(false);
  };

  return (
    <Box sx={styles.buttons}>
      <Button
        startIcon={<SvgIcon component={WeChatIcon} viewBox="0 0 512 512" />}
        onClick={handleWechatOpen}
        variant="contained"
        fullWidth
      >
        微信支付
      </Button>
      <Button
        startIcon={<SvgIcon component={AlipayIcon} viewBox="0 0 512 512" />}
        onClick={handleAlipayOpen}
        variant="contained"
        color="secondary"
        fullWidth
      >
        支付宝支付
      </Button>
      <Modal open={wechatOpen || alipayOpen} onClose={handleClose}>
        <Box sx={styles.modal}>
          <Box sx={styles.modalTitle}>
            <Typography variant="h4">
              {wechatOpen && "微信付款"}
              {alipayOpen && "支付宝付款"}
            </Typography>
            <IconButton color="inherit" onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          {wechatOpen && <img src="/wechat.png" alt="" />}
          {alipayOpen && <img src="/alipay.png" alt="" />}
        </Box>
      </Modal>
    </Box>
  );
}

interface TopUpButtonProps extends ButtonProps {
  fullWidth?: boolean;
}

export default function TopUpButton({ fullWidth = false }: TopUpButtonProps) {
  const theme = useTheme();
  const styles = {
    instructions: {
      display: "flex",
    },
    bulletText: {
      textDecoration: "underline",
    },
    modal: {
      ...modalStyle,
      width: "512px",
      padding: theme.spacing(5),
      gap: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(3),
        width: "344px",
      },
    },
    modalTitle: {
      display: "flex",
      justifyContent: "space-between",
    },
    description: {
      overflow: "auto",
      maxHeight: "50vh",
    },
  };

  const [isTopUpModalOpen, setIsTopUpModalOpen] = useState<boolean>(false);

  const handleTopUpButtonClick = () => setIsTopUpModalOpen(true);
  const handleTopUpModalClose = () => setIsTopUpModalOpen(false);

  return (
    <>
      <Button
        variant="contained"
        onClick={handleTopUpButtonClick}
        fullWidth={fullWidth}
      >
        充值
      </Button>
      <Modal open={isTopUpModalOpen} onClose={handleTopUpModalClose}>
        <Box sx={styles.modal}>
          <Box sx={styles.modalTitle}>
            <Typography id="top-up-modal" variant="h4">
              充值
            </Typography>
            <IconButton color="inherit" onClick={handleTopUpModalClose}>
              <CloseIcon />
            </IconButton>
          </Box>
          <Box sx={styles.description}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Box sx={styles.instructions}>
                  <Typography variant="h4">1.</Typography>
                  <Typography>
                    本产品目前有两种套餐：
                    <Typography>
                      {`\u2022`}
                      <Typography component="span" sx={styles.bulletText}>
                        包月套餐
                      </Typography>
                      <br />
                      GPT-3.5: 18.9元/月。
                      <br />
                      GPT-4: 189元/月。
                      <br />
                      包月套餐不限次数。套餐在额度添加时生效，并在次月同一天失效
                    </Typography>
                    <Typography>
                      {`\u2022`}
                      <Typography component="span" sx={styles.bulletText}>
                        次数套餐
                      </Typography>
                      <br />
                      GPT-3.5: 0.29元/次。
                      <br />
                      GPT-4: 2.9元/次。
                      <br />
                      提问一个问题、文档问答一次或PPT生成一次均会消耗套餐内次数。
                    </Typography>
                  </Typography>
                </Box>
              </Grid>
              <Grid item md={6} xs={12}>
                <Box sx={styles.instructions}>
                  <Typography variant="h4">2.</Typography>
                  <Typography>
                    {`\u2022`}GPT-3.5与GPT-4套餐分别计算，两者无法混合使用。
                    <br />
                    {`\u2022`}
                    您可以购买多个套餐，并按照购买的顺序进行抵扣。当一个套餐用尽或到期后，系统会开始使用下一个套餐进行抵扣。
                    <br />
                    {`\u2022`}充值成功后新增使用额度将在一天之内添加完成。
                    <br />
                    {`\u2022`}
                    请在备注内注明您的手机号以及模型选择，否则可能会延误套餐添加。
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <Box>
            <QRCodeModal />
          </Box>
        </Box>
      </Modal>
    </>
  );
}
