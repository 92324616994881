import { getAuthToken } from "../AuthProvider";
import { handleServerError } from "../Errors";
import { ServerUrl } from "../config";

export const getCustomerSupport = async () => {
  const response = await fetch(`${ServerUrl}/customer-support`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const { qrCodeUrl } = await response.json();
  return qrCodeUrl;
};
