import { UserNotAuthenticatedError } from "../../Errors";
import { getUsagePlan } from "../../services/BillingService";
import { UsagePlans } from "../../types";
import { formatDateForDisplay } from "../../utils/dateUtils";
import { formatCurrencyForDisplay } from "../../utils/numberUtils";
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TableRowProps,
  styled,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface MutedTableRowProps extends TableRowProps {
  muted?: boolean;
}

const MutedTableRow = styled((props: MutedTableRowProps) => (
  <TableRow {...props} />
))(({ theme, muted }) => ({
  opacity: muted ? 0.5 : 1,
  color: muted ? theme.palette.text.secondary : theme.palette.text.primary,
  "&:hover": {
    backgroundColor: "transparent",
  },
}));

export default function UsagePlansDisplay() {
  const styles = {
    root: {
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      overflow: "auto",
    },
    cell: {
      whiteSpace: "nowrap",
    },
  };

  const [usagePlans, setUsagePlans] = useState<UsagePlans | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const usagePlans = await getUsagePlan();
        setUsagePlans(usagePlans);
      } catch (err) {
        if (err instanceof UserNotAuthenticatedError) {
          navigate("/signin");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [navigate]);

  if (isLoading) {
    return (
      <Box sx={styles.root}>
        <CircularProgress />
      </Box>
    );
  }
  if (!usagePlans) {
    return (
      <Box sx={styles.root}>
        <Typography>您尚未购买套餐</Typography>
      </Box>
    );
  }

  const cellTitles = [
    "套餐状态",
    "模型",
    "付款金额",
    "总使用次数",
    "剩余使用次数",
    "最后使用日期",
    "有效期至",
  ];

  return (
    <Box sx={styles.root}>
      <TableContainer>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell sx={styles.cell}>购买日期</TableCell>
              {cellTitles.map((title) => (
                <TableCell key={title} align="right" sx={styles.cell}>
                  {title}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {usagePlans.plans.map((usagePlan) => (
              <MutedTableRow
                key={usagePlan.id}
                muted={!usagePlan.isActive}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {formatDateForDisplay(usagePlan.createdAt)}
                </TableCell>
                <TableCell align="right">
                  {usagePlan.isActive ? "有效" : "已失效"}
                </TableCell>
                <TableCell align="right">{usagePlan.modelType}</TableCell>
                <TableCell align="right">
                  {formatCurrencyForDisplay(usagePlan.amountPaid)}
                </TableCell>
                <TableCell align="right">
                  {usagePlan.numChatsPurchased}
                </TableCell>
                <TableCell align="right">
                  {usagePlan.numChatsRemaining}
                </TableCell>
                <TableCell align="right">
                  {formatDateForDisplay(usagePlan.updatedAt)}
                </TableCell>
                <TableCell align="right">
                  {usagePlan.expiresAt
                    ? formatDateForDisplay(usagePlan.expiresAt)
                    : "长期"}
                </TableCell>
              </MutedTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}
