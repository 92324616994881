import { AuthProvider, RequireAuth } from "./AuthProvider";
import SignInWithPassword from "./components/Auth/SignInWithPassword";
import SignInWithVerificationCode from "./components/Auth/SignInWithVerificationCode";
import SignUp from "./components/Auth/SignUp";
import Chat from "./components/Chat/Chat";
import DocumentQuery from "./components/DocumentQuery/DocumentQuery";
import GeneratePowerPoint from "./components/Powerpoint/GeneratePowerPoint";
import TopUp from "./components/TopUp/TopUp";
import { Route, Routes } from "react-router-dom";

export default function App() {
  return (
    <AuthProvider>
      <Routes>
        <Route path="/" element={<SignUp />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/password-signin" element={<SignInWithPassword />} />
        <Route path="/signin" element={<SignInWithVerificationCode />} />
        <Route
          path="/chat"
          element={
            <RequireAuth>
              <Chat />
            </RequireAuth>
          }
        />
        <Route
          path="/document-query"
          element={
            <RequireAuth>
              <DocumentQuery />
            </RequireAuth>
          }
        />
        <Route
          path="/ppt"
          element={
            <RequireAuth>
              <GeneratePowerPoint />
            </RequireAuth>
          }
        />
        <Route
          path="/topup"
          element={
            <RequireAuth>
              <TopUp />
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
}
