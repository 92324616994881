import { UserNotAuthenticatedError } from "../../Errors";
import { getDocumentQueryHistory } from "../../services/DocumentQueryService";
import { DocumentQuerySummary } from "../../types";
import History from "../Common/History";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface DocumentQuerySideNavProps {
  newDocumentCount: number;
  onQueryWithNewDocument: () => void;
  onHistoryClick: (queryId: string) => void;
}

export default function DocumentQuerySideNav({
  newDocumentCount,
  onQueryWithNewDocument,
  onHistoryClick,
}: DocumentQuerySideNavProps) {
  const [documentQueryHistory, setDocumentQueryHistory] = useState<
    DocumentQuerySummary[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const history = await getDocumentQueryHistory();
        setDocumentQueryHistory(history.history);
      } catch (err) {
        if (err instanceof UserNotAuthenticatedError) {
          navigate("/signin");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [newDocumentCount, navigate]);

  const itemContents = documentQueryHistory.map((history) => ({
    key: history.id,
    text: history.filename,
    onClick: () => onHistoryClick(history.id),
  }));

  return (
    <History
      newButtonText="新的文档问答"
      onNewButtonClick={onQueryWithNewDocument}
      itemIcon={<DescriptionOutlinedIcon />}
      itemContents={itemContents}
      isLoading={isLoading}
    />
  );
}
