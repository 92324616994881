import InputField from "../Common/InputField";
import { Button, Grid, useTheme } from "@mui/material";
import React, { useEffect, useState } from "react";

interface VerificationCodeInputProps {
  verificationCode: string;
  disabled: boolean;
  onInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick: () => void;
}

export default function VerificationCodeInput({
  verificationCode,
  onInputChange,
  disabled,
  onClick,
}: VerificationCodeInputProps) {
  const theme = useTheme();
  const styles = {
    button: {
      width: "100%",
      height: "56px",
      color: theme.palette.text.secondary,
      "&.Mui-disabled": {
        background: "#808080",
        color: "#f3f3f3",
      },
    },
  };
  const calculateRemainingTime = (): number => {
    const savedTimestamp = localStorage.getItem("verificationTimestamp");
    if (!savedTimestamp) {
      return 60;
    }
    const timestamp = Number(savedTimestamp);
    const elapsedTime = Math.floor((Date.now() - timestamp) / 1000);
    return Math.max(60 - elapsedTime, 0);
  };

  const initialRemainingTime = calculateRemainingTime();
  const [hasSent, setHasSent] = useState<boolean>(initialRemainingTime < 60);
  const [countdown, setCountdown] = useState<number>(initialRemainingTime);
  const [isSending, setIsSending] = useState<boolean>(false);

  const sendVerificationCode = async () => {
    setIsSending(true);
    // Disable the button and start the countdown.
    setHasSent(true);
    // Store the current timestamp in localStorage.
    const timestamp = Date.now();
    localStorage.setItem("verificationTimestamp", timestamp.toString());
    await onClick();
    setIsSending(false);
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;
    if (hasSent) {
      timer = setInterval(() => {
        setCountdown((prevCountdown) => {
          const newCountdown = prevCountdown - 1;
          if (newCountdown <= 0) {
            // Reset the countdown and enable the button.
            setHasSent(false);
            localStorage.removeItem("verificationTimestamp");
            return 60;
          }
          return newCountdown;
        });
      }, 1000);
    }
    return () => {
      clearInterval(timer);
    };
  }, [hasSent]);

  let buttonText;
  if (hasSent) buttonText = `${countdown}秒后重发`;
  else if (isSending) buttonText = "发送中……";
  else buttonText = "获取验证码";

  return (
    <>
      <Grid item xs={7} sm={8}>
        <InputField
          value={verificationCode}
          onChange={onInputChange}
          placeholder="验证码"
        />
      </Grid>
      <Grid item xs={5} sm={4}>
        <Button
          sx={styles.button}
          variant="outlined"
          disabled={hasSent || disabled}
          onClick={sendVerificationCode}
        >
          {buttonText}
        </Button>
      </Grid>
    </>
  );
}
