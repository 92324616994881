import ErrorAlert from "../Alerts/ErrorAlert";
import { Box, Button, Typography, useTheme } from "@mui/material";
import React, { useCallback, useState } from "react";

interface FileUploadedProps {
  uploadedFile: File | null;
  shouldDisableFileRemoval: boolean;
  onFileUploaded: (file: File) => void;
  onFileRemoved: () => void;
  onFileSubmission: () => void;
}

export default function FileUploader({
  uploadedFile,
  shouldDisableFileRemoval,
  onFileUploaded,
  onFileRemoved,
  onFileSubmission,
}: FileUploadedProps) {
  const theme = useTheme();
  const styles = {
    root: {
      width: "100%",
    },
    wrapper: {
      display: "flex",
      padding: "128px",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      textAlign: "center",
      background: "#ffffff",
      border: "3px dashed #D9D9D9",
      borderRadius: "40px",
      cursor: "pointer",
      gap: theme.spacing(1),
      [theme.breakpoints.down("xl")]: {
        padding: theme.spacing(8),
      },
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(4),
      },
    },
    uploadImage: {
      width: "160px",
      height: "160px",
    },
    uploadInstructionText: {
      color: "#5b5b5b",
    },
    clickToUploadText: {
      cursor: "pointer",
      textDecoration: "underline",
    },
    uploadedFileDisplay: {
      mt: theme.spacing(3),
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        mt: theme.spacing(1),
      },
    },
    uploadedFileName: {
      maxWidth: "376px",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
    buttons: {
      display: "flex",
      gap: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        mt: theme.spacing(1),
      },
    },
    uploadButton: {
      borderRadius: "80px",
      width: "120px",
    },
    deleteButton: {
      borderRadius: "80px",
      width: "120px",
    },
  };

  const [error, setError] = useState<string>("");
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);

  const onDrop = useCallback(
    (acceptedFile: File) => {
      onFileUploaded(acceptedFile);
    },
    [onFileUploaded]
  );
  const removeFile = useCallback(() => {
    onFileRemoved();
  }, [onFileRemoved]);

  const isFileValid = (fileName: string) => {
    const acceptedExtensions = ["doc", "docx", "txt", "pdf"];
    const fileExtension = fileName.split(".").pop()?.toLowerCase();
    return acceptedExtensions.includes(fileExtension!);
  };

  const handleFiles = (files: File[]) => {
    if (uploadedFile !== null) return;

    if (files.length > 1) {
      setError("仅支持上传一个文档");
      setIsAlertOpen(true);
      return;
    }

    const file = files[0];
    if (!isFileValid(file.name)) {
      setError("仅支持doc、docx、txt和pdf格式的文档");
      setIsAlertOpen(true);
    } else {
      setError("");
      setIsAlertOpen(false);
      onDrop(file);
    }
  };
  const handleInputFile = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (uploadedFile !== null) return;

    handleFiles(Array.from(event.target.files!));
  };
  const handleDropOver = (event: React.DragEvent<HTMLDivElement>) => {
    if (uploadedFile !== null) return;

    event.preventDefault();
  };
  const handleDropFile = (event: React.DragEvent<HTMLDivElement>) => {
    if (uploadedFile !== null) return;

    event.preventDefault();
    handleFiles(Array.from(event.dataTransfer.files));
  };

  let wrapperStyles;
  if (uploadedFile) {
    wrapperStyles = {
      ...styles.wrapper,
      opacity: 0.3,
    };
  } else {
    wrapperStyles = styles.wrapper;
  }

  return (
    <Box sx={styles.root}>
      <ErrorAlert
        open={isAlertOpen}
        error={error}
        onClose={() => setIsAlertOpen(false)}
      />
      <Box>
        <input
          accept=".doc,.docx,.txt,.pdf"
          id="contained-button-file"
          type="file"
          style={{ display: "none" }}
          onChange={handleInputFile}
        />
        <Box
          onDragOver={handleDropOver}
          onDrop={handleDropFile}
          style={{ pointerEvents: uploadedFile ? "none" : "auto" }}
          sx={wrapperStyles}
        >
          <Box
            component="img"
            src="/upload.png"
            alt=""
            sx={styles.uploadImage}
          />
          {uploadedFile && <Typography variant="h6">上传成功</Typography>}
          {!uploadedFile && (
            <Typography
              variant="h6"
              sx={styles.uploadInstructionText}
              align="left"
            >
              将文档拖拽至此处，
              <br />
              或者
              <label htmlFor="contained-button-file">
                <Typography
                  variant="inherit"
                  component="span"
                  sx={styles.clickToUploadText}
                >
                  点击选择文档
                </Typography>
              </label>
            </Typography>
          )}
        </Box>
        {uploadedFile && (
          <Box sx={styles.uploadedFileDisplay}>
            <Typography
              variant="subtitle2"
              flexGrow={1}
              sx={styles.uploadedFileName}
            >
              {uploadedFile.name}
            </Typography>
            <Box sx={styles.buttons}>
              <Button
                onClick={onFileSubmission}
                variant="contained"
                sx={styles.uploadButton}
              >
                上传
              </Button>
              <Button
                onClick={removeFile}
                disabled={shouldDisableFileRemoval}
                variant="outlined"
                sx={styles.deleteButton}
              >
                删除
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
}
