import { getAuthToken } from "../AuthProvider";
import { handleServerError } from "../Errors";
import { ServerUrl } from "../config";
import { ModelType } from "../constants";
import {
  ChatResponse,
  Conversation,
  ConversationSummaries,
  Scenario,
  Scenarios,
} from "../types";

export const getConversationSummaries = async () => {
  const response = await fetch(`${ServerUrl}/conversation-summaries`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }
  const summaries = await response.json();
  return summaries as ConversationSummaries;
};

export const getConversation = async (conversationId: string) => {
  const response = await fetch(`${ServerUrl}/conversations/${conversationId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }
  const conversation = await response.json();
  return conversation as Conversation;
};

export const getScenarios = async () => {
  const response = await fetch(`${ServerUrl}/scenarios`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }
  const scenarios = await response.json();
  return scenarios as Scenarios;
};

export const getScenario = async (scenarioId: string) => {
  const response = await fetch(`${ServerUrl}/scenarios/${scenarioId}`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }
  const scenario = await response.json();
  return scenario as Scenario;
};

export const sendChatMessage = async (
  message: string,
  modelType: ModelType,
  inputConversationId: string | null,
  scenarioId: string | null
) => {
  const messageCreatedAt = new Date();
  const payload = {
    text: message,
    modelType,
    createdAt: messageCreatedAt.toISOString(),
    conversationId: inputConversationId,
    scenarioId,
  };
  const response = await fetch(`${ServerUrl}/chat`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const resp = await response.json();
  return resp as ChatResponse;
};
