export class InvalidVerificationCodeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, InvalidVerificationCodeError.prototype);
  }
}

export class PhoneNumberAlreadyRegisteredError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, PhoneNumberAlreadyRegisteredError.prototype);
  }
}

export class UserNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, UserNotFoundError.prototype);
  }
}

export class UserNotAuthenticatedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, UserNotAuthenticatedError.prototype);
  }
}

export class UserNotEnabledError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, UserNotEnabledError.prototype);
  }
}

export class InvalidPasswordError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, InvalidPasswordError.prototype);
  }
}

export class RateLimitError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, RateLimitError.prototype);
  }
}

export class ConversationNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, ConversationNotFoundError.prototype);
  }
}

export class DocumentProcessorNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, DocumentProcessorNotFoundError.prototype);
  }
}

export class PowerPointNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, PowerPointNotFoundError.prototype);
  }
}

export class ScenarioNotFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, ScenarioNotFoundError.prototype);
  }
}

export class NoValidUsagePlanFoundError extends Error {
  constructor(message: string) {
    super(message);
    this.name = this.constructor.name;
    Object.setPrototypeOf(this, NoValidUsagePlanFoundError.prototype);
  }
}

export async function handleServerError(response: Response) {
  const responseBody = await response.json();
  const { type: errorType, message } = responseBody.detail;
  console.log(
    `Error fetching from the chat service. Status: ${response.status}. Message: ${message}`
  );
  switch (errorType) {
    case "InvalidVerificationCodeError": {
      throw new InvalidVerificationCodeError(message);
    }
    case "PhoneNumberAlreadyRegisteredException": {
      throw new PhoneNumberAlreadyRegisteredError(message);
    }
    case "UserNotFoundException": {
      throw new UserNotFoundError(message);
    }
    case "UserNotEnabledException": {
      throw new UserNotEnabledError(message);
    }
    case "UserNotAuthenticatedException": {
      throw new UserNotAuthenticatedError(message);
    }
    case "InvalidPasswordException": {
      throw new InvalidPasswordError(message);
    }
    case "RateLimitException": {
      throw new RateLimitError(message);
    }
    case "ConversationNotFoundException": {
      throw new ConversationNotFoundError(message);
    }
    case "DocumentProcessorNotFoundException": {
      throw new DocumentProcessorNotFoundError(message);
    }
    case "PowerPointNotFoundException": {
      throw new PowerPointNotFoundError(message);
    }
    case "ScenarioNotFoundException": {
      throw new ScenarioNotFoundError(message);
    }
    case "NoValidUsagePlanFoundException": {
      throw new NoValidUsagePlanFoundError(message);
    }
  }
  throw Error(message);
}
