import { useAuth } from "../../AuthProvider";
import { sendVerificationCode } from "../../services/AuthService";
import ErrorAlert from "../Alerts/ErrorAlert";
import InputField from "../Common/InputField";
import VerificationCodeInput from "./VerificationCodeInput";
import {
  Box,
  Button,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import React, { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function SignInWithVerificationCode() {
  const theme = useTheme();
  const styles = {
    root: {
      height: "100vh",
      width: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      backgroundImage: "url('/background-image.png')",
      backgroundRepeat: "no-repeat",
      backgroundSize: "cover",
      backgroundPosition: "center",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(6),
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
      },
    },
    title: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
    },
    signInButton: {
      "&.Mui-disabled": {
        background: "#808080",
        color: theme.palette.text.secondary,
      },
    },
  };

  const [phoneNumber, setPhoneNumber] = useState<string>("");
  const [isPhoneNumberValid, setIsPhoneNumberValid] = useState<boolean>(true);
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [error, setError] = useState<Error | null>(null);
  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  useState<boolean>(false);

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const auth = useAuth();

  const areAllInputFieldsValid = phoneNumber && verificationCode;

  const handlePhoneNumberInputBlur = () => {
    // Validate that the input contains exactly 11 numeric characters
    const isValidPhoneNumber = /^\d{11}$/.test(phoneNumber);
    setIsPhoneNumberValid(isValidPhoneNumber);
  };
  const handleSendVerificationCodeButtonClick = async () => {
    if (!phoneNumber) {
      return;
    }
    try {
      await sendVerificationCode(phoneNumber);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
        setIsAlertOpen(true);
      }
    }
  };
  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!areAllInputFieldsValid) {
      return;
    }
    try {
      await auth.signInWithVerificationCode(phoneNumber, verificationCode);
      navigate("/chat");
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
        setIsAlertOpen(true);
      }
    }
  };

  return (
    <Box sx={styles.root}>
      {error && (
        <ErrorAlert
          open={isAlertOpen}
          error={error.message}
          onClose={() => setIsAlertOpen(false)}
        />
      )}
      <Box sx={styles.content}>
        <Box sx={styles.title}>
          <Typography variant={isMobile ? "h3" : "h2"} color="textSecondary">
            登录
          </Typography>
          <Typography variant={isMobile ? "body1" : "h5"} color="textSecondary">
            还没有账号？
            <Link href="/">注册</Link>
          </Typography>
        </Box>
        <Box component="form" noValidate onSubmit={handleSubmit}>
          <Grid container rowSpacing={3} columnSpacing={2}>
            <Grid item xs={12}>
              <InputField
                value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                onBlur={handlePhoneNumberInputBlur}
                error={!isPhoneNumberValid}
                placeholder="手机号"
                errorText="手机号格式错误"
              />
            </Grid>
            <VerificationCodeInput
              verificationCode={verificationCode}
              disabled={!Boolean(phoneNumber) || !isPhoneNumberValid}
              onInputChange={(e) => setVerificationCode(e.target.value)}
              onClick={handleSendVerificationCodeButtonClick}
            />
            <Grid item xs={12}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!areAllInputFieldsValid}
                sx={styles.signInButton}
              >
                登录
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
}
