import { UserNotAuthenticatedError } from "../../Errors";
import { getConversationSummaries } from "../../services/ChatService";
import { ConversationSummary } from "../../types";
import History from "../Common/History";
import ChatOutlinedIcon from "@mui/icons-material/ChatOutlined";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

interface ChatSideNavProps {
  newConversationCount: number;
  onStartNewConversation: () => void;
  onConversationClick: (conversationId: string) => void;
}

export default function ChatSideNav({
  newConversationCount,
  onStartNewConversation,
  onConversationClick,
}: ChatSideNavProps) {
  const [conversationSummaries, setConversationSummaries] = useState<
    ConversationSummary[]
  >([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const summaries = await getConversationSummaries();
        setConversationSummaries(summaries.summaries);
      } catch (err) {
        if (err instanceof UserNotAuthenticatedError) {
          navigate("/signin");
        }
      } finally {
        setIsLoading(false);
      }
    };
    fetchData();
  }, [newConversationCount, navigate]);

  const itemContents = conversationSummaries.map((conversation) => ({
    key: conversation.id,
    text: conversation.summary,
    onClick: () => onConversationClick(conversation.id),
  }));

  return (
    <History
      newButtonText="新的对话"
      onNewButtonClick={onStartNewConversation}
      itemIcon={<ChatOutlinedIcon />}
      itemContents={itemContents}
      isLoading={isLoading}
    />
  );
}
