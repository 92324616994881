import { getAuthToken } from "../AuthProvider";
import { handleServerError } from "../Errors";
import { ServerUrl } from "../config";
import {
  SignInWithPasswordResponse,
  SignInWithVerificationCodeResponse,
  SignUpResponse,
  User,
} from "../types";

export const getUser = async () => {
  const response = await fetch(`${ServerUrl}/user`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const user = await response.json();
  return user as User;
};

export const sendVerificationCode = async (phoneNumber: string) => {
  const payload = { phoneNumber };
  const response = await fetch(`${ServerUrl}/send-otp`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    await handleServerError(response);
  }
};

export const signUp = async (
  phoneNumber: string,
  verificationCode: string,
  referralCode: string | null
) => {
  const payload = {
    phoneNumber,
    verificationCode,
    referralCode,
  };
  const response = await fetch(`${ServerUrl}/sign-up`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const resp = await response.json();
  return resp as SignUpResponse;
};

export const signInWithPassword = async (
  phoneNumber: string,
  password: string
) => {
  const payload = { phoneNumber, password };
  const response = await fetch(`${ServerUrl}/password-sign-in`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const resp = await response.json();
  return resp as SignInWithPasswordResponse;
};

export const signInWithVerificationCode = async (
  phoneNumber: string,
  verificationCode: string
) => {
  const payload = { phoneNumber, verificationCode };
  const response = await fetch(`${ServerUrl}/verification-code-sign-in`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(payload),
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const resp = await response.json();
  return resp as SignInWithVerificationCodeResponse;
};
