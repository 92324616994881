import {
  NoValidUsagePlanFoundError,
  UserNotAuthenticatedError,
} from "../../Errors";
import {
  GPT35,
  GPT4,
  HistoryDrawerWidthDesktop,
  ModelType,
  NavigationDrawerWidthDesktop,
} from "../../constants";
import {
  generatePowerPoint,
  getPowerPoint,
} from "../../services/PowerPointService";
import ErrorAlert from "../Alerts/ErrorAlert";
import SuccessAlert from "../Alerts/SuccessAlert";
import Copyright from "../Common/Copyright";
import Navigation from "../Common/Navigation";
import PowerPointSideNav from "./PowerPointSideNav";
import {
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
  TextField,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const MinNumSlides = 1;
const MaxNumSlides = 30;
const DefaultLanguage = "Chinese";

export default function GeneratePowerPoint() {
  const theme = useTheme();
  const styles = {
    root: {
      height: "100vh",
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
    },
    content: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      ml: `${NavigationDrawerWidthDesktop + HistoryDrawerWidthDesktop}px`,
      padding: "0 56px",
      height: "100%",
      overflow: "auto",
      background: theme.palette.background.paper,
      [theme.breakpoints.down("sm")]: {
        ml: 0,
        padding: "0 16px",
      },
    },
    loadingSpinner: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      flexGrow: 1,
    },
    inputForm: {
      display: "flex",
      flexDirection: "column",
      flexGrow: 1,
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
      },
    },
    title: {
      display: "flex",
      flexDirection: "column",
      gap: theme.spacing(2),
      mt: theme.spacing(10),
      mb: theme.spacing(4),
      maxWidth: "264px",
      [theme.breakpoints.down("sm")]: {
        alignItems: "center",
        mt: theme.spacing(4),
        mb: theme.spacing(4),
      },
    },
    description: {
      [theme.breakpoints.down("sm")]: {
        textAlign: "center",
      },
    },
    gridItemContainer: {
      [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(1),
      },
    },
    topicInput: {
      "& .MuiOutlinedInput-root": {
        borderRadius: "24px",
      },
    },
    formControl: {
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      },
    },
    formLabel: {
      fontSize: theme.typography.h5.fontSize,
      color: theme.palette.text.primary,
      mb: theme.spacing(2),
      "&.Mui-focused": {
        color: theme.palette.text.primary,
      },
    },
    numSlidesInput: {
      display: "flex",
      alignItems: "center",
    },
    numSlidesInputField: {
      width: "96px",
      "& .MuiOutlinedInput-root": {
        borderRadius: "24px",
      },
    },
    radioButton: {
      color: theme.palette.text.primary,
      "&.Mui-checked": {
        color: theme.palette.text.primary,
      },
    },
    generateButton: {
      borderRadius: "80px",
      fontSize: "1rem",
      width: "296px",
      "&.Mui-disabled": {
        background: "#dedede",
        color: "#c8c8c8",
      },
    },
    downloadButton: {
      borderRadius: "80px",
      fontSize: "1rem",
      width: "296px",
    },
    copyright: {
      position: "sticky",
      bottom: 0,
      pb: theme.spacing(2),
      width: "100%",
      background: "inherit",
      [theme.breakpoints.down("sm")]: {
        pb: theme.spacing(1),
      },
    },
  };

  const [isAlertOpen, setIsAlertOpen] = useState<boolean>(false);
  const [isGenerating, setIsGenerating] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [topic, setTopic] = useState<string>("");
  const [numSlides, setNumSlides] = useState<string>("");
  const [language, setLanguage] = useState<string>(DefaultLanguage);
  const [modelType, setModelType] = useState<ModelType>(GPT35);
  const [url, setUrl] = useState<string>("");

  const [newPowerPointCount, setNewPowerPointCount] = useState<number>(0);
  const [error, setError] = useState<Error | null>(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleGenerateNewPowerPoint = () => {
    setTopic("");
    setNumSlides("");
    setLanguage(DefaultLanguage);
    setModelType(GPT35);
    setUrl("");
  };
  const handleHistoryClick = async (pptId: string) => {
    setIsLoading(true);
    const powerpoint = await getPowerPoint(pptId);
    setTopic(powerpoint.params.topic);
    setNumSlides(powerpoint.params.numSlides.toString());
    setLanguage(powerpoint.params.language);
    setModelType(GPT35);
    setUrl(powerpoint.url);
    setIsLoading(false);
  };
  const isDownloadable = Boolean(url);
  const isInputDisabled = isGenerating || isDownloadable;
  const isGenerationDisabled =
    !topic || !numSlides || !language || isDownloadable;
  const handleNumSlidesInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const inputValue = event.target.value;
    const numericValue = parseInt(inputValue);
    if (
      inputValue === "" ||
      (numericValue >= MinNumSlides && numericValue <= MaxNumSlides)
    ) {
      setNumSlides(inputValue);
    }
  };
  const handleGenerateButtonClick = async () => {
    if (isGenerationDisabled) return;

    setIsGenerating(true);
    try {
      const { url } = await generatePowerPoint(
        topic,
        parseInt(numSlides),
        language,
        modelType
      );
      setUrl(url);
      setIsAlertOpen(true);
      setNewPowerPointCount(newPowerPointCount + 1);
    } catch (err) {
      if (err instanceof UserNotAuthenticatedError) {
        navigate("/signin");
      }
      if (err instanceof NoValidUsagePlanFoundError) {
        setIsAlertOpen(true);
        setError(err);
      }
    } finally {
      setIsGenerating(false);
    }
  };
  const handleDownloadButtonClick = () => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <Box sx={styles.root}>
      {isAlertOpen && (
        <SuccessAlert
          open={isAlertOpen}
          message="PPT生成成功"
          onClose={() => setIsAlertOpen(false)}
        ></SuccessAlert>
      )}
      {error && (
        <ErrorAlert
          open={isAlertOpen}
          error={error.message}
          onClose={() => setIsAlertOpen(false)}
        />
      )}
      <PowerPointSideNav
        newPowerPointCount={newPowerPointCount}
        onGenerateNewPowerPoint={handleGenerateNewPowerPoint}
        onHistoryClick={handleHistoryClick}
      />
      <Box sx={styles.content}>
        {isLoading && (
          <Box sx={styles.loadingSpinner}>
            <CircularProgress />
            <Typography sx={{ mt: 1 }}>读取中，请稍后……</Typography>
          </Box>
        )}
        {!isLoading && (
          <Box sx={styles.inputForm}>
            <Box sx={styles.title}>
              <Typography variant="h4">创作PPT</Typography>
              <Typography variant="h6" sx={styles.description}>
                制作简易PPT，目前仅支持纯文字输出
              </Typography>
            </Box>
            <Grid
              container
              rowSpacing={isMobile ? 4 : 8}
              columnSpacing={isMobile ? 0 : 8}
            >
              <Grid item xs={12} sm={6} container sx={styles.gridItemContainer}>
                <FormControl sx={styles.formControl}>
                  <FormLabel sx={styles.formLabel}>主题</FormLabel>
                  <TextField
                    fullWidth
                    multiline
                    rows={isMobile ? 10 : 15}
                    placeholder="在这里输入主题内容"
                    value={topic}
                    onChange={(e) => setTopic(e.target.value)}
                    sx={styles.topicInput}
                    disabled={isInputDisabled}
                  />
                </FormControl>
              </Grid>
              <Grid
                item
                xs={12}
                sm={6}
                container
                direction={isMobile ? "row" : "column"}
                justifyContent={isMobile ? "space-around" : "space-between"}
                sx={styles.gridItemContainer}
                rowSpacing={isMobile ? 5 : 0}
              >
                <Grid item>
                  <FormControl sx={styles.formControl}>
                    <FormLabel sx={styles.formLabel}>页数</FormLabel>
                    <Box sx={styles.numSlidesInput}>
                      <TextField
                        value={numSlides}
                        type="number"
                        inputProps={{ min: MinNumSlides, max: MaxNumSlides }}
                        onChange={handleNumSlidesInputChange}
                        sx={styles.numSlidesInputField}
                        disabled={isInputDisabled}
                      />
                      <Typography variant="h6" sx={{ marginLeft: 1 }}>
                        页
                      </Typography>
                    </Box>
                  </FormControl>
                </Grid>
                <Grid item container>
                  <Grid item xs={6} lg={3}>
                    <FormControl sx={styles.formControl}>
                      <FormLabel sx={styles.formLabel}>语言</FormLabel>
                      <RadioGroup
                        value={language}
                        onChange={(e) => setLanguage(e.target.value)}
                      >
                        <FormControlLabel
                          value="Chinese"
                          control={<Radio sx={styles.radioButton} />}
                          label="中文"
                          disabled={isInputDisabled}
                        />
                        <FormControlLabel
                          value="English"
                          control={<Radio sx={styles.radioButton} />}
                          label="英文"
                          disabled={isInputDisabled}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6} lg={3}>
                    <FormControl sx={styles.formControl}>
                      <FormLabel sx={styles.formLabel}>模型</FormLabel>
                      <RadioGroup
                        value={modelType}
                        onChange={(e) =>
                          setModelType(e.target.value as ModelType)
                        }
                      >
                        <FormControlLabel
                          value={GPT35}
                          control={<Radio sx={styles.radioButton} />}
                          label="GPT-3.5"
                          disabled={isInputDisabled}
                        />
                        <FormControlLabel
                          value={GPT4}
                          control={<Radio sx={styles.radioButton} />}
                          label="GPT-4"
                          disabled={isInputDisabled}
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
                <Grid
                  item
                  container
                  direction="column"
                  justifyContent="space-between"
                  alignItems={isMobile ? "center" : "flex-start"}
                  gap={2}
                >
                  <Button
                    disabled={isGenerationDisabled || isGenerating}
                    variant="contained"
                    sx={styles.generateButton}
                    onClick={handleGenerateButtonClick}
                  >
                    {isGenerating ? (
                      <CircularProgress size={24} color="inherit" />
                    ) : (
                      "生成PPT"
                    )}
                  </Button>
                  <Button
                    disabled={!isDownloadable}
                    variant="outlined"
                    sx={styles.downloadButton}
                    onClick={handleDownloadButtonClick}
                  >
                    下载PPT
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        )}
        <Box sx={styles.copyright}>
          <Copyright />
        </Box>
      </Box>
      <Navigation navigationItemKey="ppt" />
    </Box>
  );
}
