import { getAuthToken } from "../AuthProvider";
import { handleServerError } from "../Errors";
import { ServerUrl } from "../config";
import { UsagePlans } from "../types";

export const getUsagePlan = async () => {
  const response = await fetch(`${ServerUrl}/usage-plans`, {
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${getAuthToken()}`,
    },
  });
  if (!response.ok) {
    await handleServerError(response);
  }

  const usagePlans = await response.json();
  return usagePlans as UsagePlans;
};
