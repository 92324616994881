import SendOutlinedIcon from "@mui/icons-material/SendOutlined";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import { ChangeEvent, useState } from "react";

interface UserInputProps {
  isGeneratingMessage: boolean;
  onSubmit: (message: string) => void;
  disabled?: boolean;
}

export default function UserInput({
  isGeneratingMessage,
  onSubmit,
  disabled = false,
}: UserInputProps) {
  const styles = {
    inputField: {
      width: "100%",
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          border: "1px solid #D9D9D9",
          borderRadius: "24px",
          boxShadow: "0px 0px 40px rgba(0, 0, 0, 0.1)",
        },
      },
    },
  };

  const [message, setMessage] = useState<string>("");
  const [isComposing, setIsComposing] = useState<boolean>(false);
  const handleInputChange = (
    event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    setMessage(event.target.value);
  };
  const canSendMessage = () => {
    return !isGeneratingMessage && message.length !== 0;
  };
  const handleSendMessage = () => {
    if (!canSendMessage()) {
      return;
    }

    onSubmit(message);
    setMessage("");
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === "Enter" && event.shiftKey) {
      event.preventDefault();
      setMessage(message + "\n");
    } else if (event.key === "Enter" && !isComposing) {
      event.preventDefault();
      handleSendMessage();
    }
  };

  return (
    <TextField
      id="user-input"
      sx={styles.inputField}
      multiline
      variant="outlined"
      value={message}
      onChange={handleInputChange}
      onKeyDown={handleKeyDown}
      onCompositionStart={() => setIsComposing(true)}
      onCompositionEnd={() => setIsComposing(false)}
      placeholder="在此输入信息，Shift+Enter换行"
      disabled={disabled}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              edge="end"
              disabled={!canSendMessage()}
              onClick={handleSendMessage}
            >
              <SendOutlinedIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
